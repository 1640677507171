import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

// loadStripe wrapper
const useStripePromise = () => {
    const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    const [stripePromise, setStripePromise] = useState(null);

    if (!stripePromise) {
        const promise = loadStripe(stripePublicKey);
        setStripePromise(promise);
    }

    return stripePromise;
};

export default useStripePromise;
