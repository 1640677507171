import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePlans } from '../../context/PlanContext';
import './Plans.scss';

const PlanComponent = ({ plan }) => {
    return (
        <div className={plan.isSelected ? 'plan selected-plan' : 'plan'}>
            <div className={plan.isSelected ? 'name selected-name' : 'name'}>
                {plan.name}
            </div>
            <div className="price">
                <span className="price-1">{plan.formattedAmount}</span>
                <span className="price-2">/month</span>
            </div>
            <div className="details">
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.linksCreatedPerMonth}
                    </span>{' '}
                    links/month
                </div>
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.customBackHalvesPerMonth}
                    </span>{' '}
                    custom back-halves/month
                </div>
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.analyticsPeriod.count}{' '}
                        {plan.actionLimits.analyticsPeriod.scale}
                    </span>{' '}
                    of click data
                </div>
            </div>
            <div className="btn-container">
                <Link to={`/signup?plan=${plan.name}`}>
                    <Button
                        variant={plan.isSelected ? 'contained' : 'outlined'}
                        style={{
                            textTransform: 'none',
                        }}
                        className={plan.isSelected ? 'selected-btn' : ''}
                    >
                        Get Started
                    </Button>
                </Link>
            </div>
        </div>
    );
};

const Plans = () => {
    const { plans, fetchPlans } = usePlans();
    const [isLoading, setIsLoading] = useState(true);
    const [formattedPlans, setFormattedPlans] = useState([]);
    const [error, setError] = useState();

    const loadPlans = async () => {
        if (!plans.length) {
            await fetchPlans();
        } else {
            const updatedPlans = plans.map((plan) => {
                // set the selected plan
                if (plan.id === 2) {
                    plan.isSelected = true;
                }

                // format the scale to make it singular if applicable
                const scale = plan.actionLimits.analyticsPeriod.scale;
                if (plan.actionLimits.analyticsPeriod.count === 1) {
                    if (scale === 'days') {
                        plan.actionLimits.analyticsPeriod.scale = 'day';
                    } else if (scale === 'weeks') {
                        plan.actionLimits.analyticsPeriod.scale = 'week';
                    } else if (scale === 'months') {
                        plan.actionLimits.analyticsPeriod.scale = 'month';
                    } else if (scale === 'years') {
                        plan.actionLimits.analyticsPeriod.scale = 'year';
                    }
                }

                return plan;
            });

            setFormattedPlans(updatedPlans);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadPlans();
    }, [plans, fetchPlans]);

    return (
        <div className="plans-component">
            {isLoading ? (
                <div className="plans-loader">
                    <div className="content">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-spin icon"
                        />
                    </div>
                </div>
            ) : (
                <div>
                    {error && (
                        <div className="error">
                            <div className="error-header">Uh-Oh</div>
                            <div>
                                Looks like we had a problem fetching plans
                            </div>
                        </div>
                    )}
                    {!error && (
                        <div className="plans">
                            {formattedPlans.map((plan, key) => {
                                return <PlanComponent key={key} plan={plan} />;
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Plans;
