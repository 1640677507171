import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'; // Import the reCAPTCHA component
import { Button, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, TextField, Select } from '../index';
import { SignUpBilling } from '../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { usePlans } from '../../context/PlanContext';
import './SignUp.scss';

const SignUp = () => {
    const { plans, fetchPlans } = usePlans();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        planId: '',
    });

    const [isRegistered, setIsRegistered] = useState(false);
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const captchaRef = useRef();
    // const [plans, setPlans] = useState([]);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setEmailError('');
    };

    const validatePassword = () => {
        // Password validation criteria, 8 chars, one uppercase, one lowercase, one number
        const passwordRegex =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        const isValidPassword = passwordRegex.test(formData.password);

        if (!isValidPassword) {
            // Password does not meet criteria
            setPasswordError(
                'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number.'
            );
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    // signupform submitted
    const handleSubmit = async (e) => {
        e.preventDefault();
        signUp();
    };

    // called from submit and from successful payment
    const signUp = async (data = null) => {
        if (!validatePassword()) {
            return;
        }

        setIsLoading(true);

        let dataToSend = {};

        // if data is being passed in, use that instead (payment success)
        // it includes orirginal token that was shared during nav
        if (data) {
            dataToSend = data;
        } else {
            // otherwise use formdata and use the value of captcha directly
            dataToSend = {
                ...formData,
                token: captchaRef.current.getValue(),
            };
        }

        try {
            const response = await axios.post(
                `${apiBaseUrl}/signup`,
                dataToSend
            );

            setIsRegistered(true);
        } catch (error) {
            console.error(error.response.data);
            if (error.response.data.code === 'duplicate_email') {
                setEmailError('Email address is already in use');
            } else {
                Toast.error('Problem signing up');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // j-todo: clean up here...
    const loadPlans = async () => {
        if (!plans.length) {
            await fetchPlans();
        } else {
            const queryString = window.location.search;

            // Create a new URLSearchParams object with the query string
            const queryParams = new URLSearchParams(queryString);
            const urlPlan = queryParams.get('plan');

            const selectedPlan = plans.find((plan) => plan.name === urlPlan);

            if (selectedPlan) {
                setFormData({
                    ...formData,
                    planId: selectedPlan.id,
                });
            } else {
                const freePlan = plans.find((plan) => plan.name === 'Free');
                setFormData({
                    ...formData,
                    planId: freePlan.id,
                });
            }
        }
    };

    const [captchaToken, setCaptchaToken] = useState();
    const [showCaptchaError, setShowCaptchaError] = useState(false);

    const nextStep = async () => {
        const token = captchaRef.current.getValue();

        if (!validatePassword()) {
            return;
        }

        if (!token) {
            setShowCaptchaError(true);
            return;
        }

        try {
            const response = await axios.post(
                `${apiBaseUrl}/signup/validate-email`,
                {
                    email: formData.email,
                }
            );
        } catch (error) {
            if (error.response.data.code === 'duplicate_email') {
                setEmailError('Email address is already in use');
            } else {
                Toast.error('Problem signing up');
            }
            return;
        }

        setCaptchaToken(token);
        setShowPayment(true);
    };

    const handleBackClick = (planId) => {
        setFormData({
            ...formData,
            planId: planId,
        });
        setShowPayment(false);
    };

    const handleSuccess = (paymentFormData) => {
        const { line2, line1, city, state, postal_code, country } =
            paymentFormData.address;

        const data = {
            ...formData,
            address_line_1: line1,
            address_line_2: line2,
            city,
            state,
            postal_code,
            country,
            customer_type: paymentFormData.customer_type,
            customer_id: paymentFormData.customer_id,
            phone: paymentFormData.phone,
            token: paymentFormData.token,
            planId: paymentFormData.planId,
        };
        signUp(data);
    };

    useEffect(() => {
        captchaRef.current?.reset();
        loadPlans();
    }, [plans, fetchPlans]);

    return (
        <div className="signup-component">
            {isRegistered && (
                <div className="form-container registered">
                    <div className="success">
                        <FontAwesomeIcon
                            icon={faCheckSquare}
                            className="icon"
                        />{' '}
                        Registration successful!
                    </div>
                    <div>Please check your email for verification.</div>
                </div>
            )}
            {!isRegistered && !showPayment && (
                <div className="form-container">
                    {formData.planId && formData.planId !== 1 && (
                        <div className="steps-container">
                            <div className="active-step">Sign Up</div>
                            <div>Payment Info</div>
                            <div>Get Started</div>
                        </div>
                    )}
                    <div className="form-sub-title">Create your account</div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-item">
                            <TextField
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                className="text-field"
                                required
                            />
                        </div>
                        <div className="form-item">
                            <TextField
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                className="text-field"
                                required
                            />
                        </div>
                        <div className="form-item">
                            <TextField
                                id="email"
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="text-field"
                                required
                                error={!!emailError} // Set error state based on passwordError
                                helperText={emailError} // Display error message
                            />
                        </div>
                        <div className="form-item">
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className="text-field"
                                required
                                error={!!passwordError} // Set error state based on passwordError
                                helperText={passwordError} // Display error message
                            />
                        </div>
                        <div className="form-item">
                            <Select
                                id="planId"
                                label="Selected Plan"
                                name="planId"
                                value={formData.planId}
                                onChange={handleInputChange}
                                className="text-field"
                                required
                                options={plans.map((plan) => {
                                    return { value: plan.id, label: plan.name };
                                })}
                            />
                        </div>

                        <div
                            className={`captcha-container form-item ${
                                showCaptchaError ? 'captcha-error' : ''
                            }`}
                        >
                            <ReCAPTCHA
                                sitekey={recaptchaKey}
                                ref={captchaRef}
                            />
                        </div>

                        <div className="btn-container">
                            {/* <Button
                                variant="contained"
                                type="submit"
                                style={{ textTransform: 'none' }}
                                disabled={isLoading}
                                className="btn"
                            >
                                Create Your Account
                            </Button> */}
                            {formData.planId === 1 ? (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ textTransform: 'none' }}
                                    disabled={isLoading}
                                    className="btn"
                                >
                                    Create Your Account
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    style={{ textTransform: 'none' }}
                                    className="btn"
                                    onClick={nextStep}
                                    disabled={
                                        !formData.firstName ||
                                        !formData.lastName ||
                                        !formData.email ||
                                        !formData.password ||
                                        !formData.planId
                                    }
                                >
                                    Payment Details
                                </Button>
                            )}
                        </div>
                        <div className="disclaimer">
                            By creating an account, you agree to Lemur Links{' '}
                            <Link to="/terms-of-service" className="link">
                                Terms of Service
                            </Link>
                            .
                        </div>
                    </form>
                </div>
            )}
            {!isRegistered && showPayment && (
                <div className="billing-container">
                    <SignUpBilling
                        name={`${formData.firstName} ${formData.lastName}`}
                        email={formData.email}
                        planId={formData.planId}
                        onBackClick={handleBackClick}
                        plans={plans}
                        onSuccess={handleSuccess}
                        token={captchaToken}
                    />
                </div>
            )}
        </div>
    );
};

export default SignUp;
