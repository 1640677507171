const sharingSteps = {
    youtube: {
        prettyName: 'YouTube',
        link: 'https://youtube.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Channel',
                body: 'Visit YouTube and go to your channel',
            },
            {
                header: 'Customize Channel',
                body: 'On your channel page, you should see an "Customize Channel" button. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'In the Basic Info tab, find the Links section. Press the "Add link" button and paste your link in the URL field and select "Publish"',
            },
        ],
    },
    instagram: {
        prettyName: 'Instagram',
        link: 'https://instagram.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Profile',
                body: 'Visit your Instagram profile and go to the Edit Profile section',
            },
            {
                header: 'Edit Profile',
                body: 'On your profile page, you should see an "Edit Profile" button. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'In the Links section, press the "Add external link" button. Paste your link in the URL field and select "Done"',
            },
        ],
    },
    facebook: {
        prettyName: 'Facebook',
        link: 'https://facebook.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Profile',
                body: 'Visit Facebook and navigate to your profile by clicking on your profile picture in the top right corner',
            },
            {
                header: 'Edit Profile',
                body: 'On your profile page, you should see an "Edit Profile" button located below your cover photo. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'Scroll down to the "Contact and Basic Info" section, then click "Edit" next to "Websites" to paste your link',
            },
        ],
    },
    tiktok: {
        prettyName: 'TikTok',
        link: 'https://tiktok.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Profile',
                body: 'Visit TikTok and tap on the "Me" icon at the bottom right corner of the screen to access your profile',
            },
            {
                header: 'Edit Profile',
                body: 'On your profile page, you should see an "Edit Profile" button located next to your profile picture. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'Under the "Profile" section, tap on "Website" to paste your link',
            },
        ],
    },
    linkedin: {
        prettyName: 'LinkedIn',
        link: 'https://linkedin.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Profile',
                body: 'Visit LinkedIn and click on "Me" in the top menu, then select "View profile" from the dropdown',
            },
            {
                header: 'Edit Profile',
                body: 'On your profile page, you should see an "Edit public profile & URL" button on the right side of your profile page. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'Scroll down to the "Contact Info" section, then click the pencil icon to paste your link',
            },
        ],
    },
    twitter: {
        prettyName: 'X (formerly Twitter)',
        link: 'https://twitter.com',
        steps: [
            {
                header: 'Grab your LemurLink',
                body: 'Copy your LemurLink below',
            },
            {
                header: 'Go to Your Profile',
                body: 'Visit X (Twitter) and click on your profile picture in the top right corner, then select "Profile" from the dropdown menu',
            },
            {
                header: 'Edit Profile',
                body: 'On your profile page, you should see an "Edit profile" button which looks like a pencil icon next to your profile picture. Click on it',
            },
            {
                header: 'Add Your Link',
                body: 'Scroll down to the "Profile" section and paste your link in the "Website" field',
            },
        ],
    },
};

export default sharingSteps;
