import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
    faChartLine,
    faLink,
    faLocationDot,
    faMobileScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { useSpring, animated } from 'react-spring';

import './Aggregate.scss';

const Aggregate = ({
    isLoading,
    data,
    aggregateShowUniqueLinks,
    dateRange,
}) => {
    const {
        totalClicks,
        totalUniqueLinksClicked,
        totalUniqueRegions,
        totalUniqueDevices,
    } = data;

    const animatedTotalClicks = useSpring({
        totalClicks,
        from: { totalClicks: 0 },
        config: { duration: 800 },
    });

    const animatedUniqueLinks = useSpring({
        totalUniqueLinksClicked,
        from: { totalUniqueLinksClicked: 0 },
        config: { duration: 700 },
    });

    const animatedUniqueRegions = useSpring({
        totalUniqueRegions,
        from: { totalUniqueRegions: 0 },
        config: { duration: 600 },
    });

    const animatedUniqueDevices = useSpring({
        totalUniqueDevices,
        from: { totalUniqueDevices: 0 },
        config: { duration: 500 },
    });

    return (
        <div className="aggregate-data-container">
            {!isLoading && (
                <>
                    <h2 className="header-date">
                        <FontAwesomeIcon
                            icon={faCalendar}
                            className="date-icon"
                        />
                        {moment(dateRange.startDate).format('MMM D, YYYY')} -{' '}
                        {moment(dateRange.endDate).format('MMM D, YYYY')}
                    </h2>
                    <div className="data-container">
                        <div className="data">
                            <FontAwesomeIcon
                                icon={faChartLine}
                                className="data-icon"
                            />
                            Total Clicks:{' '}
                            <animated.span className="data-value">
                                {animatedTotalClicks.totalClicks.to((value) =>
                                    Math.floor(value)
                                )}
                            </animated.span>
                        </div>
                        {aggregateShowUniqueLinks && (
                            <div className="data">
                                <FontAwesomeIcon
                                    icon={faLink}
                                    className="data-icon"
                                />
                                Unique Links:{' '}
                                <animated.span className="data-value">
                                    {animatedUniqueLinks.totalUniqueLinksClicked.to(
                                        (value) => Math.floor(value)
                                    )}
                                </animated.span>
                            </div>
                        )}
                        <div className="data">
                            <FontAwesomeIcon
                                icon={faLocationDot}
                                className="data-icon"
                            />
                            Unique Regions:{' '}
                            <animated.span className="data-value">
                                {animatedUniqueRegions.totalUniqueRegions.to(
                                    (value) => Math.floor(value)
                                )}
                            </animated.span>
                        </div>
                        <div className="data">
                            <FontAwesomeIcon
                                icon={faMobileScreenButton}
                                className="data-icon"
                            />
                            Unique Devices:{' '}
                            <animated.span className="data-value">
                                {animatedUniqueDevices.totalUniqueDevices.to(
                                    (value) => Math.floor(value)
                                )}
                            </animated.span>
                        </div>
                    </div>
                </>
            )}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default Aggregate;
