import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Toast, TextField } from '../index';
import EditModal from './EditModal/EditModal';

import './Users.scss';

const Users = () => {
    const { user } = useAuth();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const fetchUsers = async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiBaseUrl}/admin/users`, {
                headers: {
                    Authorization: user ? `Bearer ${user.token}` : '',
                },
            });

            setUsers(response.data.users);
        } catch (error) {
            console.error('Fetch users error:', error);
            Toast.error('An error occurred fetching users');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (row) => {
        setEditModalOpen(true);
        setSelectedUser(row);
    };

    const filteredData = users.filter(
        (row) =>
            row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.permission_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
            row.plan_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // todo: need filtered data
    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = useMemo(() => {
        const filteredData = users.filter(
            (row) =>
                row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.first_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                row.last_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                row.permission_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                row.plan_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // ordering
        if (orderBy && order) {
            return filteredData.slice().sort((a, b) => {
                const valueA = a[orderBy];
                const valueB = b[orderBy];

                // Check if the values are numbers
                if (!isNaN(valueA) && !isNaN(valueB)) {
                    // If both values are numbers, compare them as numbers
                    return order === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (
                    typeof valueA === 'string' &&
                    typeof valueB === 'string'
                ) {
                    // If both values are strings, compare them using localeCompare
                    return order === 'asc'
                        ? valueA.localeCompare(valueB)
                        : valueB.localeCompare(valueA);
                } else {
                    // If one of the values is a date, you can convert them to Date objects and compare
                    const dateA = new Date(valueA);
                    const dateB = new Date(valueB);
                    return order === 'asc' ? dateA - dateB : dateB - dateA;
                }
            });
        }

        return filteredData;
    }, [users, orderBy, order, searchTerm]);

    const handleCloseModal = (wasupdated) => {
        setEditModalOpen(false);

        if (wasupdated) {
            fetchUsers();
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className="users-component">
            <div className="main-content">
                <div className="users-header">Users</div>
                <div className="filters-container">
                    <div className="filter-item">
                        <TextField
                            label="Search users"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="text-field"
                            type="search"
                            InputProps={{
                                startAdornment: (
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        style={{
                                            marginRight: '8px',
                                            color: '#757575',
                                        }}
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
                {/* done loading users  */}
                {!isLoading && users.length > 0 && (
                    <div className="parent-table-container">
                        <TableContainer
                            className="users-table"
                            style={{ borderRadius: '0 0 6px 6px' }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {/* id */}
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'id'}
                                                direction={order}
                                                onClick={() => handleSort('id')}
                                            >
                                                ID
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* first name */}
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === 'first_name'
                                                }
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('first_name')
                                                }
                                            >
                                                First Name
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* last name */}
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'last_name'}
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('last_name')
                                                }
                                            >
                                                Last Name
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* email */}
                                        <TableCell className="email-column">
                                            <TableSortLabel
                                                active={orderBy === 'email'}
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('email')
                                                }
                                            >
                                                Email
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* permission */}
                                        <TableCell className="permission-column">
                                            <TableSortLabel
                                                active={
                                                    orderBy ===
                                                    'permission_name'
                                                }
                                                direction={order}
                                                onClick={() =>
                                                    handleSort(
                                                        'permission_name'
                                                    )
                                                }
                                            >
                                                Permission
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* plan */}
                                        <TableCell className="plan-column">
                                            <TableSortLabel
                                                active={orderBy === 'plan_name'}
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('plan_name')
                                                }
                                            >
                                                Plan
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* verified */}
                                        <TableCell className="verified-column">
                                            <TableSortLabel
                                                active={
                                                    orderBy === 'is_verified'
                                                }
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('is_verified')
                                                }
                                            >
                                                Verified
                                            </TableSortLabel>
                                        </TableCell>

                                        {/* active */}
                                        <TableCell className="active-column">
                                            <TableSortLabel
                                                active={orderBy === 'is_active'}
                                                direction={order}
                                                onClick={() =>
                                                    handleSort('is_active')
                                                }
                                            >
                                                Active
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => (
                                            <TableRow
                                                key={index}
                                                className="table-row"
                                                onClick={() =>
                                                    handleRowClick(row)
                                                }
                                            >
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>
                                                    {row.first_name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.last_name}
                                                </TableCell>
                                                <TableCell className="email-column">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell className="permission-column">
                                                    {row.permission_name}
                                                </TableCell>
                                                <TableCell className="plan-column">
                                                    {row.plan_name}
                                                </TableCell>
                                                <TableCell className="verified-column">
                                                    {row.is_verified
                                                        ? 'Yes'
                                                        : 'No'}
                                                </TableCell>
                                                <TableCell className="active-column">
                                                    {row.is_active
                                                        ? 'Yes'
                                                        : 'No'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 55]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                )}
                <div className="table-spacer"></div>
                {/* done loading no users  */}
                {!isLoading && !users.length && (
                    <div>
                        <div className="no-users-container">
                            <div className="no-users-text">No User data</div>
                        </div>
                    </div>
                )}
                {/* loading  */}
                {isLoading && (
                    <div className="loader">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-spin icon"
                        />
                    </div>
                )}
            </div>
            <EditModal
                open={editModalOpen}
                onClose={handleCloseModal}
                selectedUser={selectedUser}
            />
        </div>
    );
};

export default Users;
