import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';

import './ManagePlan.scss';

const ManagePlan = () => {
    const { user } = useAuth();
    const [count, setCount] = useState(user.actionLimits.analyticsPeriod.count);
    const [scale, setScale] = useState(user.actionLimits.analyticsPeriod.scale);

    useEffect(() => {
        let analyticsPeriodScale = user.actionLimits.analyticsPeriod.scale;
        if (count === 1) {
            if (analyticsPeriodScale === 'days') {
                setScale('day');
            } else if (analyticsPeriodScale === 'weeks') {
                setScale('week');
            } else if (analyticsPeriodScale === 'months') {
                setScale('month');
            } else if (analyticsPeriodScale === 'years') {
                setScale('year');
            }
        }
    }, []);

    return (
        <div className="manage-plan-container">
            <div className="main-content">
                <div className="support-header">Manage Plan</div>
                <div className="card support-items">
                    <div className="plan-container">
                        <div className="plan-header">
                            Your currently on the{' '}
                            <span className="bold">{user.plan_name}</span> plan
                        </div>
                        <div className="plan-sub-header">
                            Your plan includes:
                        </div>
                        <div>
                            <span className="bold">
                                {user.actionLimits.linksCreatedPerMonth}
                            </span>{' '}
                            links per month
                        </div>
                        <div>
                            <span className="bold">
                                {user.actionLimits.customBackHalvesPerMonth}
                            </span>{' '}
                            custom back-halves per month
                        </div>
                        <div>
                            <span className="bold">
                                {count} {scale}
                            </span>{' '}
                            of click data
                        </div>
                    </div>
                    <div className="support-message">
                        To manage your plan, please email us at
                        support@lemurlinks.com
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManagePlan;
