import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './LineChart.scss';

// j-todo: figure out some styles and such, tooltip
const LineChartComponent = ({ isLoading, data }) => {
    return (
        <div className="line-chart-container">
            {!isLoading && data.length > 0 && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="clicks"
                            stroke="#6699CC"
                            activeDot={{ r: 6 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            )}
            {!isLoading && data.length == 0 && (
                <div className="no-data">No Click data</div>
            )}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default LineChartComponent;
