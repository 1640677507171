import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004080',
        },
        secondary: {
            main: '#004e75',
        },
        // Add more customizations for colors if needed
    },
    typography: {
        fontFamily: 'Roboto, sans-serif', // Customize the default font family
    },
    // Add more theme customizations as needed
});

export default theme;
