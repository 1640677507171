import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from '@mui/material';
import moment from 'moment';
import { useAuth } from '../../../context/AuthContext';
import { DateRangePicker, Select } from '../../index';

import './FilterModal.scss';

const FilterModal = ({
    open,
    onClose,
    onApply,
    initialLinkType,
    initialDateRange,
}) => {
    const { user } = useAuth();

    const LinkTypes = {
        all: 0,
        custom: 1,
        nonCustom: 2,
    };

    const defaultStartDate = moment(user.created_at).format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD'); // today
    const [dateRange, setDateRange] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });

    const [linkType, setLinkType] = useState(LinkTypes.all);

    const handleModalClose = (event) => {
        // Check if the click event occurred outside the modal content
        if (event.target.classList.contains('filter-modal')) {
            onCancel(); // Close the modal
        }
    };

    const onCancel = () => {
        setLinkType(initialLinkType);
        setDateRange(initialDateRange);
        onClose();
    };

    const onReset = () => {
        setLinkType(LinkTypes.all);
        setDateRange({
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });
    };

    const applyFilters = () => {
        onApply({ linkType, dateRange });
    };

    useEffect(() => {
        setLinkType(initialLinkType);
        setDateRange(initialDateRange);
    }, [initialLinkType]);

    return (
        <Modal open={open} onClose={onClose} onClick={handleModalClose}>
            <div className="filter-modal">
                <div className="modal-content">
                    {/* header  */}
                    <div className="modal-header-container">
                        <div className="modal-header">Add Filters</div>
                        <div>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="modal-close-btn"
                                onClick={onCancel}
                            />
                        </div>
                    </div>

                    {/* body  */}
                    <div className="modal-body">
                        <div className="date-created-label">
                            Date Created Date Range
                        </div>
                        <div className="filter-item date-range-container">
                            <DateRangePicker
                                onChange={(newDateRange) =>
                                    setDateRange(newDateRange)
                                }
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                minDate={defaultStartDate}
                            />
                        </div>
                        <div className="filter-item">
                            <Select
                                id="planId"
                                label="Selected Plan"
                                name="planId"
                                value={linkType}
                                onChange={(e) => setLinkType(e.target.value)}
                                className="text-field"
                                required
                                options={[
                                    { value: LinkTypes.all, label: 'All' },
                                    {
                                        value: LinkTypes.custom,
                                        label: 'With custom back-halves',
                                    },
                                    {
                                        value: LinkTypes.nonCustom,
                                        label: 'Without custom back-halves',
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    {/* btn container  */}
                    <div className="btn-container">
                        <div className="reset-btn">
                            <Button
                                onClick={onReset}
                                style={{ textTransform: 'none' }}
                            >
                                <FontAwesomeIcon
                                    icon={faUndo}
                                    className="reset-icon"
                                />{' '}
                                Reset
                            </Button>
                        </div>
                        <Button
                            onClick={onCancel}
                            variant="outlined"
                            style={{ textTransform: 'none' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={applyFilters}
                            style={{ textTransform: 'none' }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
