import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { SignUpBilling, Toast } from '..';
import { useAuth } from '../../context/AuthContext';
import { usePlans } from '../../context/PlanContext';
import { Link, useNavigate } from 'react-router-dom';

import './Upgrade.scss';

const PlanComponent = ({ plan, setSelectedPlanId }) => {
    return (
        <div className={plan.isSelected ? 'plan selected-plan' : 'plan'}>
            <div className={plan.isSelected ? 'name selected-name' : 'name'}>
                {plan.name}
            </div>
            <div className="price">
                <span className="price-1">{plan.formattedAmount}</span>
                <span className="price-2">/month</span>
            </div>
            <div className="details">
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.linksCreatedPerMonth}
                    </span>{' '}
                    links/month
                </div>
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.customBackHalvesPerMonth}
                    </span>{' '}
                    custom back-halves/month
                </div>
                <div>
                    <span className="detail-highlight">
                        {plan.actionLimits.analyticsPeriod.count}{' '}
                        {plan.actionLimits.analyticsPeriod.scale}
                    </span>{' '}
                    of click data
                </div>
            </div>
            <div className="btn-container">
                <Button
                    variant="contained"
                    style={{
                        textTransform: 'none',
                    }}
                    onClick={() => setSelectedPlanId(plan.id)}
                >
                    Upgrade to {plan.name}
                </Button>
            </div>
        </div>
    );
};

const Upgrade = () => {
    const { user, updateUser } = useAuth();
    const { plans, fetchPlans } = usePlans();
    const navigate = useNavigate();

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [error, setError] = useState();
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleSuccess = (paymentFormData) => {
        const { line2, line1, city, state, postal_code, country, planId } =
            paymentFormData.address;

        const data = {
            address_line_1: line1,
            address_line_2: line2,
            city,
            state,
            postal_code,
            country,
            customer_type: paymentFormData.customer_type,
            customer_id: paymentFormData.customer_id,
            phone: paymentFormData.phone,
            plan_id: paymentFormData.planId,
        };

        // update user
        updateUserRecord(data);
    };

    const updateUserRecord = async (payload) => {
        try {
            // j-todo: this needs to fetch the full user with remaining actions
            const response = await axios.post(
                `${apiBaseUrl}/user`,
                {
                    ...payload,
                },
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            // update the user state if we succeeded
            updateUser({
                phone: payload.phone,
                address_line_1: payload.address_line_1,
                address_line_2: payload.address_line_2,
                city: payload.city,
                state: payload.state,
                postal_code: payload.postal_code,
                plan_id: selectedPlanId,
            });
            setSelectedPlanId(null);
            setShowSuccess(true);

            Toast.success('Successfully upgraded!');
        } catch (error) {
            console.error('Login error:', error);
            Toast.error('Problem updating profile');
        } finally {
            // setIsLoading(false);
        }
    };

    const loadPlans = async () => {
        if (!plans.length) {
            await fetchPlans();
        } else {
            const filteredPlans = plans.filter((plan) => plan.id !== 1);
            setFilteredPlans(filteredPlans);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user.plan_id === 1) {
            loadPlans();
        } else {
            navigate('/settings/manage-plan');
        }
    }, [plans, fetchPlans]);

    return (
        <div className="upgrade-container">
            <div className="main-content">
                {!showSuccess && (
                    <div className="header-container">
                        <div className="upgrade-header">
                            Upgrade Your Link Tracking Experience With Lemur
                            Links
                        </div>
                        <div className="upgrade-sub-header">
                            Enjoy increased link-limits and advanced analytics
                            with our paid plans
                        </div>
                    </div>
                )}
                <div className="card">
                    {isLoading && (
                        <div className="plans-loader">
                            <div className="content">
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="fa-spin icon"
                                />
                            </div>
                        </div>
                    )}
                    {!isLoading && !selectedPlanId && !showSuccess && (
                        <div>
                            {error && (
                                <div className="error">
                                    <div className="error-header">Uh-Oh</div>
                                    <div>
                                        Looks like we had a problem fetching
                                        plans
                                    </div>
                                </div>
                            )}
                            {!error && (
                                <div className="plans">
                                    {filteredPlans.map((plan, key) => {
                                        return (
                                            <PlanComponent
                                                key={key}
                                                plan={plan}
                                                setSelectedPlanId={
                                                    setSelectedPlanId
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    {!isLoading && selectedPlanId && (
                        <div className="billing-container">
                            <SignUpBilling
                                name={`${user.first_name} ${user.last_name}`}
                                email={user.email}
                                planId={selectedPlanId}
                                onBackClick={() => setSelectedPlanId(null)}
                                plans={plans}
                                onSuccess={handleSuccess}
                            />
                        </div>
                    )}
                    {!isLoading && showSuccess && (
                        <div className="success-container">
                            <div className="success">
                                <FontAwesomeIcon
                                    icon={faCheckSquare}
                                    className="icon"
                                />{' '}
                                Upgrade successful!
                            </div>
                            <div>
                                You can manage your plan in your{' '}
                                <Link to="/settings">settings</Link> at anytime
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Upgrade;
