import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { LinkActions } from '../index';
import './LinkCard.scss';

const LinkCard = ({ link, linkEnabled, onUpdate }) => {
    const url = `${process.env.REACT_APP_LINK_API_BASE_URL}/${link.slug}`;
    link.url = url;
    const createdAt = moment(link.created_at).format('MMM DD, YYYY');

    return (
        <div className="link-card-container">
            <div className="link-card">
                <div className="header-container">
                    <div className="link-title">
                        {linkEnabled ? (
                            <Link to={`/link/details/${link.slug}`}>
                                {link.title}
                            </Link>
                        ) : (
                            <>{link.title}</>
                        )}
                    </div>
                    <div className="link-controls-desktop">
                        <LinkActions link={link} onUpdate={onUpdate} />
                    </div>
                </div>
                <div>
                    <a href={url} target="_blank" className="slug-link">
                        {url}
                    </a>
                </div>
                <div>
                    <a
                        href={link.destination_url}
                        target="_blank"
                        className="destination-url"
                    >
                        {link.destination_url}
                    </a>
                </div>
                <div className="card-footer">
                    <div className="footer-item">
                        <FontAwesomeIcon
                            icon={faCalendar}
                            className="footer-icon"
                        />{' '}
                        {createdAt}
                    </div>
                </div>
                <div className="link-controls-mobile">
                    <LinkActions link={link} onUpdate={onUpdate} />
                </div>
            </div>
        </div>
    );
};

export default LinkCard;
