import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faGear,
    faLink,
    faAnglesRight,
    faAnglesLeft,
    faPlus,
    faChartSimple,
    faUser,
} from '@fortawesome/free-solid-svg-icons'; // Import the icons you need
import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import LemurIcon from './lemur_icon.png';
import './SideBar.scss'; // Import your SCSS for styling

const SideBar = () => {
    const { isAdmin } = useAuth();
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    const location = useLocation();

    return (
        <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
            <div className="img-container">
                <Link to="/home">
                    <img
                        src={LemurIcon}
                        className="lemur-img"
                        alt="Lemur Links Logo"
                    />
                </Link>
            </div>
            <div className="toggle-button-container">
                <FontAwesomeIcon
                    icon={isExpanded ? faAnglesLeft : faAnglesRight}
                    className="toggle-button"
                    onClick={toggleSidebar}
                />{' '}
            </div>
            <div className="btn-container">
                <Link to="/link/create">
                    <Button
                        variant="contained"
                        style={{
                            textTransform: 'none',
                            // minWidth: '0px',
                        }}
                        className="btn"
                    >
                        {isExpanded ? (
                            'Create New'
                        ) : (
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                    fontSize: '16px',
                                }}
                            />
                        )}
                    </Button>
                </Link>
            </div>
            <ul className="menu-items">
                <li className="divider-item"></li>
                <li>
                    <Link
                        to="/home"
                        className={
                            location.pathname === '/home' ? 'active' : ''
                        }
                    >
                        <FontAwesomeIcon icon={faHome} />
                        {isExpanded && <span className="item-text">Home</span>}
                    </Link>
                </li>
                <li>
                    <Link
                        to="/links"
                        className={
                            location.pathname === '/links' ? 'active' : ''
                        }
                    >
                        <FontAwesomeIcon icon={faLink} />
                        {isExpanded && <span className="item-text">Links</span>}
                    </Link>
                </li>
                <li>
                    <Link
                        to="/analytics"
                        className={
                            location.pathname === '/analytics' ? 'active' : ''
                        }
                    >
                        <FontAwesomeIcon icon={faChartSimple} />
                        {isExpanded && (
                            <span className="item-text">Analytics</span>
                        )}
                    </Link>
                </li>
                {/* admin routes  */}
                {isAdmin() && (
                    <>
                        <li>
                            <Link
                                to="/users"
                                className={
                                    location.pathname === '/users'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <FontAwesomeIcon icon={faUser} />
                                {isExpanded && (
                                    <span className="item-text">Users</span>
                                )}
                            </Link>
                        </li>
                    </>
                )}
                <li className="divider-item"></li>
                <li>
                    <Link
                        to="/settings"
                        className={
                            location.pathname === '/settings' ? 'active' : ''
                        }
                    >
                        <FontAwesomeIcon icon={faGear} />
                        {isExpanded && (
                            <span className="item-text">Settings</span>
                        )}
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default SideBar;
