import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { Toast, TextField } from '../index';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './Settings.scss';

const Settings = () => {
    const { user, updateUser, signOut } = useAuth();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [insecurePasswordError, setInsecurePasswordError] = useState('');
    const [missingPassword, setMissingPassword] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // reset form to user state values
    const onCancel = () => {
        setFormData({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phone: user.phone || '',
            address_line_1: user.address_line_1 || '',
            address_line_2: user.address_line_2 || '',
            city: user.city || '',
            state: user.state || '',
            postal_code: user.postal_code || '',
            password: '',
            newPassword: '',
            confirmPassword: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setPasswordError('');
        setMissingPassword('');
        setInsecurePasswordError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // passwords dont match
        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordError("Passwords don't match");
            return;
        } else if (formData.newPassword) {
            // new password and confirm password are equal, test its strength
            // Password validation criteria, 8 chars, one uppercase, one lowercase, one number
            const passwordRegex =
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
            const isValidPassword = passwordRegex.test(formData.newPassword);
            if (!isValidPassword) {
                setInsecurePasswordError(
                    'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number.'
                );
                return;
            }
        }

        const payload = { ...formData };
        delete payload.confirmPassword; // never send this just used here

        // if there is no current password after validation
        // remove current and new password from being sent
        if (!payload.password) {
            delete payload.password;
            delete payload.newPassword;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                `${apiBaseUrl}/user`,
                {
                    ...payload,
                },
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            // update the user state if we succeeded
            updateUser({
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                address_line_1: formData.address_line_1,
                address_line_2: formData.address_line_2,
                city: formData.city,
                state: formData.state,
                postal_code: formData.postal_code,
            });

            // update the form
            setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                address_line_1: formData.address_line_1,
                address_line_2: formData.address_line_2,
                city: formData.city,
                state: formData.state,
                postal_code: formData.postal_code,
                password: '',
                newPassword: '',
                confirmPassword: '',
            });

            Toast.success('Profile updated!');

            // if password was set, we force signout to signin with new password
            if (payload.password) {
                signOut();
            }
        } catch (error) {
            console.error('Login error:', error);
            Toast.error('Problem updating profile');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                phone: user.phone || '',
                address_line_1: user.address_line_1 || '',
                address_line_2: user.address_line_2 || '',
                city: user.city || '',
                state: user.state || '',
                postal_code: user.postal_code || '',
                password: '',
                newPassword: '',
                confirmPassword: '',
            });
        }
    }, []);
    return (
        <div className="settings-container">
            <div className="main-content">
                <div className="settings-header">Settings</div>
                <div className="card">
                    <div className="form-container">
                        <div className="member">
                            Member since{' '}
                            {moment(user.verified_at).format('MMMM D, YYYY')}
                        </div>
                        <form onSubmit={handleSubmit} className="settings-form">
                            <div className="settings-section-header">
                                Profile
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    type="tel"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="email"
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                style={{
                                                    marginRight: '8px',
                                                    color: '#2E8B57',
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                            <div className="settings-section-header">
                                Address
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="address_line_1"
                                    label="Address Line 1"
                                    name="address_line_1"
                                    value={formData.address_line_1}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="address_line_2"
                                    label="Address Line 2"
                                    name="address_line_2"
                                    value={formData.address_line_2}
                                    onChange={handleInputChange}
                                    className="text-field"
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="postal_code"
                                    label="Postal Code"
                                    name="postal_code"
                                    value={formData.postal_code}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    required
                                />
                            </div>
                            <div className="settings-section-header">
                                <div>Password</div>
                                <div className="changing-message">
                                    Changing your password will require you to
                                    login
                                </div>
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="password"
                                    label="Current Password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    type="password"
                                    error={!!missingPassword} // Set error state based on passwordError
                                    helperText={missingPassword} // Display error message
                                    required={
                                        !!formData.newPassword ||
                                        !!formData.confirmPassword
                                    }
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="newPassword"
                                    label="New Password"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    type="password"
                                    error={
                                        !!passwordError ||
                                        !!insecurePasswordError
                                    } // Set error state based on passwordError
                                    helperText={
                                        passwordError || insecurePasswordError
                                    } // Display error message
                                    required={
                                        !!formData.password ||
                                        !!formData.confirmPassword
                                    }
                                />
                            </div>
                            <div className="form-item">
                                <TextField
                                    id="confirmPassword"
                                    label="Confirm New Password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    className="text-field"
                                    type="password"
                                    error={!!passwordError} // Set error state based on passwordError
                                    helperText={passwordError} // Display error message
                                    required={
                                        !!formData.password ||
                                        !!formData.newPassword
                                    }
                                />
                            </div>
                            <div className="btn-container">
                                <Button
                                    variant="outlined"
                                    style={{ textTransform: 'none' }}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ textTransform: 'none' }}
                                    disabled={isLoading}
                                >
                                    Update
                                </Button>
                            </div>
                            <div className="settings-section-header">Plan</div>
                            <div>
                                To manage your plan go{' '}
                                <Link
                                    to="/settings/manage-plan"
                                    className="link"
                                >
                                    here
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
