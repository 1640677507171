import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { Toast } from '../components';

const PlanContext = createContext();

export const usePlans = () => {
    return useContext(PlanContext);
};

// j-todo: plan error
export const PlanProvider = ({ children }) => {
    const [plans, setPlans] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchPlans = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/plans`);
            response.data.plans.forEach((plan) => {
                // Convert price to US dollars and format it nicely
                const amountUsd = plan.amount / 100;
                const formattedPrice = amountUsd.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                // Remove '.00' if price is a whole number
                plan.formattedAmount =
                    amountUsd % 1 === 0
                        ? amountUsd.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                          })
                        : formattedPrice;
            });

            setPlans(response.data.plans);
        } catch (error) {
            console.error('Error fetching plans:', error);
            Toast.error('Error fetching plans');
        }
    };

    return (
        <PlanContext.Provider value={{ plans, fetchPlans }}>
            {children}
        </PlanContext.Provider>
    );
};
