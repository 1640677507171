import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';

import './NotFound.scss';

const NotFound = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    return (
        <div className="notfound-component">
            <div className="content">
                <h1 className="header">404 - Not Found</h1>
                <p className="details">
                    The page you are looking for does not exist.
                </p>
                {!user && (
                    <Button
                        onClick={() => navigate('/signin')}
                        variant="contained"
                        type="submit"
                        style={{ textTransform: 'none' }}
                        className="btn"
                    >
                        Sign In
                    </Button>
                )}
            </div>
        </div>
    );
};

export default NotFound;
