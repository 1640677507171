import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './Table.scss';

const TableComponent = (props) => {
    const { data, isLoading, locationType } = props;
    const rowsPerPage = 5;

    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableStyle = {
        height: '100%',
    };

    const getHeader = () => {
        switch (locationType) {
            case 'city':
                return 'City';
            case 'region':
                return 'State'; // this doesnt cover every country but US is most likely
            case 'postal_code':
                return 'Postal Code';
            case 'country':
            default:
                return 'Country';
        }
    };

    const header = getHeader();

    return (
        <div className="table-container">
            {!isLoading && data.length > 0 && (
                <>
                    <TableContainer style={tableStyle}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{header}</TableCell>
                                    <TableCell>Clicks</TableCell>
                                    <TableCell>%</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {row.locationValue}
                                            </TableCell>
                                            <TableCell>{row.count}</TableCell>
                                            <TableCell>
                                                {row.percentage}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                        />
                    </TableContainer>
                </>
            )}
            {!isLoading && data.length == 0 && (
                <div className="no-data">No location data</div>
            )}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default TableComponent;
