import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCopy, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip } from '@mui/material';
import EditModal from './EditModal/EditModal';
import ShareModal from './ShareModal/ShareModal';

import './LinkActions.scss';

const LinkActions = (props) => {
    const { link, onUpdate } = props;

    const [copied, setCopied] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const handleCloseModal = (wasupdated) => {
        setEditModalOpen(false);

        if (wasupdated) {
            onUpdate();
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(link.url);
        setTimeout(() => {
            setCopied(true);
        }, 300);
    };

    const toolTipClosed = () => {
        setCopied(false);
    };

    return (
        <>
            <div className="link-action-btn-container">
                <Tooltip
                    title="Copied!"
                    onClose={toolTipClosed}
                    open={copied}
                    leaveDelay={2000}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: '#2E8B57',
                                color: 'white',
                                fontSize: '14px',
                            },
                        },
                    }}
                >
                    <Button
                        variant="outlined"
                        className="link-action-btn"
                        onClick={handleCopy}
                    >
                        <FontAwesomeIcon icon={faCopy} className="btn-icon" />
                    </Button>
                </Tooltip>
            </div>
            <div className="link-action-btn-container">
                <Button
                    variant="outlined"
                    className="link-action-btn"
                    onClick={() => setEditModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faPen} className="btn-icon" />
                </Button>
            </div>
            <div className="link-action-btn-container">
                <Button
                    variant="outlined"
                    className="link-action-btn"
                    onClick={() => setShareModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faShareNodes} className="btn-icon" />
                </Button>
            </div>
            <EditModal
                open={editModalOpen}
                onClose={handleCloseModal}
                link={link}
            />
            <ShareModal
                open={shareModalOpen}
                onClose={() => setShareModalOpen(false)}
                link={link}
            />
        </>
    );
};

export default LinkActions;
