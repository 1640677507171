import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthContext';
import { Toast, LinksTable } from '../index';

import './Links.scss';
import moment from 'moment';

const Links = () => {
    const { user } = useAuth();
    const [links, setLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchLinks = async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiBaseUrl}/link`, {
                headers: {
                    Authorization: user ? `Bearer ${user.token}` : '',
                },
            });
            const tableData = response.data.links.map((link) => {
                return {
                    id: link.id,
                    title: link.title,
                    slug: link.slug,
                    url: `https://lemr.link/${link.slug}`,
                    totalClicks: link.totalClicks,
                    destinationUrl: link.destination_url,
                    isCustom: link.is_custom,
                    createdAt: moment(link.created_at).format('MM/DD/YY'),
                    created_at: link.created_at, // used for filtering
                };
            });
            setLinks(tableData);
        } catch (error) {
            console.error('Fetch links error:', error);
            Toast.error('An error occurred fetching links');
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdate = () => {
        fetchLinks();
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    return (
        <div className="links-component">
            <div className="main-content">
                <div className="links-header">Links</div>
                {/* done loading links  */}
                {!isLoading && links.length > 0 && (
                    <div className="parent-table-container">
                        <LinksTable data={links} onUpdate={onUpdate} />
                    </div>
                )}
                <div className="table-spacer"></div>
                {/* done loading no links  */}
                {!isLoading && !links.length && (
                    <div>
                        <div className="no-links-container">
                            <div className="no-link-text">
                                When you create links they will appear here.
                            </div>
                            <div className="btn-container">
                                <Link to="/link/create">
                                    <Button
                                        variant="contained"
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        className="btn"
                                    >
                                        Get Started
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* loading  */}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default Links;
