import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { Toast, TextField } from '../../index';

import './EditModal.scss';

// j-todo: drop downs permissions/plans/verified
const EditModal = ({ open, onClose, selectedUser }) => {
    const { user } = useAuth();
    const [formData, setFormData] = useState({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        permissionName: '',
        planName: '',
        isVerified: false,
        isActive: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showDeleteView, setShowDeleteView] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const params = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            permission: formData.permissionName,
            planName: formData.planName,
            isVerified: formData.isVerified,
            isActive: formData.isActive,
        };

        try {
            const response = await axios.patch(
                `${apiBaseUrl}/admin/user/${selectedUser.id}`,
                params,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            Toast.success('User successfully updated!');
            onClose(true); // trigger the update call
        } catch (error) {
            console.error('update user error:', error);
            Toast.error('Problem updating user');
        } finally {
            setIsLoading(false);
        }
    };

    const handleModalClose = (event) => {
        // Check if the click event occurred outside the modal content
        if (event.target.classList.contains('user-edit-modal')) {
            onCancel(); // Close the modal
        }
    };

    const onCancel = () => {
        // reset back to value passed in originally
        setFormData({
            id: selectedUser.id || '',
            email: selectedUser.email || '',
            firstName: selectedUser.first_name || '',
            lastName: selectedUser.last_name || '',
            permissionName: selectedUser.permission_name || '',
            planName: selectedUser.plan_name || '',
            isVerified: selectedUser.is_verified || false,
            isActive: selectedUser.is_active || false,
        });
        setShowDeleteView(false);
        onClose();
    };

    // j-todo:
    // will want to do soft deletes here
    // more work needs to be done
    const handleConfirmDelete = async () => {
        console.log('deleting', formData);
        return;
    };

    useEffect(() => {
        if (selectedUser) {
            setFormData({
                id: selectedUser.id || '',
                email: selectedUser.email || '',
                firstName: selectedUser.first_name || '',
                lastName: selectedUser.last_name || '',
                permissionName: selectedUser.permission_name || '',
                planName: selectedUser.plan_name || '',
                isVerified: selectedUser.is_verified || false,
                isActive: selectedUser.is_active || false,
            });
        } else {
            // Reset form data if selectedUser is null or undefined
            setFormData({
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                permissionName: '',
                planName: '',
                isVerified: false,
                isActive: false,
            });
        }
    }, [selectedUser]);

    return (
        <Modal open={open} onClose={onClose} onClick={handleModalClose}>
            <div className="user-edit-modal">
                {!showDeleteView ? (
                    <div className="modal-content">
                        <div className="modal-header-container">
                            <div className="modal-header">Edit User</div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="modal-close-btn"
                                    onClick={onCancel}
                                />
                            </div>
                        </div>

                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="form-item">
                                    <TextField
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>
                                <div className="form-item">
                                    <TextField
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>
                                <div className="form-item">
                                    <TextField
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>
                                <div className="form-item">
                                    <TextField
                                        id="permission"
                                        label="Permission"
                                        name="permissionName"
                                        value={formData.permissionName}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>
                                <div className="form-item">
                                    <TextField
                                        id="plan"
                                        label="Plan"
                                        name="planName"
                                        value={formData.planName}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>

                                <div className="form-item">
                                    <TextField
                                        id="verified"
                                        label="Verified"
                                        name="isVerified"
                                        value={formData.isVerified}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>

                                <div className="form-item">
                                    <TextField
                                        id="active"
                                        label="Active"
                                        name="isActive"
                                        value={formData.isActive}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>

                                <div className="btn-container">
                                    {/* j-todo: more work for delete  */}
                                    <div className="delete-btn">
                                        {/* <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() =>
                                                setShowDeleteView(true)
                                            }
                                            style={{
                                                textTransform: 'none',
                                            }}
                                            disabled={isLoading}
                                        >
                                            Delete
                                        </Button> */}
                                    </div>
                                    <Button
                                        onClick={onCancel}
                                        variant="outlined"
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="modal-content">
                        <div className="modal-header-container">
                            <div className="modal-header">Confrim Deletion</div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="modal-close-btn"
                                    onClick={onClose}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="delete-text">
                                Are you sure you want to delete this user? This
                                action cannot be undone.
                            </div>
                            <div className="btn-container-delete">
                                <Button
                                    onClick={() => setShowDeleteView(false)}
                                    variant="outlined"
                                    style={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleConfirmDelete}
                                    variant="contained"
                                    color="error"
                                    style={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default EditModal;
