import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
    Header,
    Main,
    Plans,
    SignUp,
    SignIn,
    NotFound,
    Verify,
    HomePage,
    Settings,
    SideBar,
    SideBarMobile,
    Links,
    LinksCreate,
    LinkDetails,
    Analytics,
    TermsOfService,
    Users,
    Support,
    Upgrade,
    ManagePlan,
} from './components';
import { ProtectedRoute, PublicRoute } from './routes';
import { useAuth } from './context/AuthContext';

import './App.scss';

const App = () => {
    const { user, isAdmin } = useAuth();

    return (
        <div className="app">
            {/* SideBars control their own hide and show breakpoints,
                    only one will be shown at a time */}
            {user && (
                <>
                    <SideBar />
                    <SideBarMobile />
                </>
            )}
            <div className="main-content">
                <Header />
                <Routes>
                    {/* public routes  */}
                    <Route element={<PublicRoute />}>
                        <Route exact path="/" element={<Main />} />
                        <Route exact path="/plans" element={<Plans />} />
                        <Route exact path="/signup" element={<SignUp />} />
                        <Route exact path="/signin" element={<SignIn />} />
                        <Route
                            exact
                            path="/verify/:token"
                            element={<Verify />}
                        />
                        <Route
                            exact
                            path="/terms-of-service"
                            element={<TermsOfService />}
                        />
                    </Route>
                    {/* private routes  */}
                    <Route element={<ProtectedRoute />}>
                        <Route element={<HomePage />} path="/home" exact />
                        <Route element={<Settings />} path="/settings" />
                        <Route
                            element={<ManagePlan />}
                            path="/settings/manage-plan"
                        />
                        <Route element={<Links />} path="/links" />
                        <Route element={<LinksCreate />} path="/link/create" />
                        <Route element={<Support />} path="/support" />
                        <Route element={<Upgrade />} path="/upgrade" />
                        <Route
                            element={<LinkDetails />}
                            path="/link/details/:slug"
                        />
                        <Route
                            element={
                                <Analytics
                                    pageTitle="Analytics"
                                    charts={{
                                        aggregate: true,
                                        topPerforming: true,
                                        byDevice: true,
                                        byLocation: true,
                                        overTime: true,
                                        aggregateShowUniqueLinks: true,
                                    }}
                                />
                            }
                            path="/analytics"
                        />
                        {/* admin routes  */}
                        {isAdmin() && (
                            <>
                                <Route element={<Users />} path="/users" />
                            </>
                        )}
                    </Route>
                    {/* 404  */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </div>
    );
};

export default App;
