import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faCopy,
    faLink,
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip } from '@mui/material';

const ShareView = ({ setView, link, onClose }) => {
    const [copied, setCopied] = useState(false);

    const onCancel = () => {
        onClose();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(link.url);
        setTimeout(() => {
            setCopied(true);
        }, 300);
    };

    const toolTipClosed = () => {
        setCopied(false);
    };

    return (
        <>
            <div className="modal-header-container">
                <div className="modal-header">Share Your Link</div>
                <div>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="modal-close-btn"
                        onClick={onCancel}
                    />
                </div>
            </div>
            <div className="modal-body">
                <div className="description">
                    Expand your audience and attract more visitors by sharing
                    your link across the web.
                </div>
                <div className="url-container">
                    <div className="url">{link.url}</div>
                    <div className="link-action-btn-container">
                        <Tooltip
                            title="Copied!"
                            onClose={toolTipClosed}
                            open={copied}
                            leaveDelay={2000}
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: '#2E8B57',
                                        color: 'white',
                                        fontSize: '14px',
                                    },
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                className="link-action-btn"
                                onClick={handleCopy}
                            >
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className="btn-icon"
                                />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('profiles')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon icon={faLink} className="icon" />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">
                            On your social profiles
                        </div>
                        <div className="social-description">
                            Add it to Instagram, YouTube, TikTok etc.
                        </div>
                    </div>
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="icon arrow"
                        />
                    </div>
                </div>
                {/* j-todo: fill this flow in */}
                {/* <div className="social-container">
                    <div className="main-social-icon">
                        <FontAwesomeIcon icon={faMessage} className="icon" />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">With your followers</div>
                        <div className="social-description">
                            Share it in posts, messages, texts and more
                        </div>
                    </div>
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="icon arrow"
                        />
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ShareView;
