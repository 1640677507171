import { toast as defaultToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faInfoCircle,
    faWarning,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import './Toast.scss';

const customToast = {
    success: (message, options) => {
        defaultToast.success(message, {
            autoClose: 3000,
            ...options,
            className: 'custom-success-toast',
            icon: <FontAwesomeIcon icon={faCheckCircle} />,
        });
    },

    info: (message, options) => {
        defaultToast.info(message, {
            autoClose: 3000,
            ...options,
            className: 'custom-info-toast',
            icon: <FontAwesomeIcon icon={faInfoCircle} />,
        });
    },

    warning: (message, options) => {
        defaultToast.warning(message, {
            autoClose: 3000,
            ...options,
            className: 'custom-warning-toast',
            icon: <FontAwesomeIcon icon={faWarning} />,
        });
    },

    error: (message, options) => {
        defaultToast.error(message, {
            autoClose: 3000,
            ...options,
            className: 'custom-error-toast',
            icon: <FontAwesomeIcon icon={faExclamationCircle} />,
        });
    },
};

export default customToast;
