import { TextField as MUITextField, InputLabel } from '@mui/material';

const TextField = ({ label, placeholder, disabled, ...otherProps }) => {
    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <InputLabel
                shrink
                style={{
                    fontSize: '21px',
                    fontWeight: '500',
                    color: disabled ? '#AAA' : '#333',
                    marginBottom: '-2px',
                }}
            >
                {label}
            </InputLabel>
            <MUITextField
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                {...otherProps}
                inputProps={{
                    style: {
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    },
                    placeholder: placeholder,
                }}
                disabled={disabled}
            />
        </div>
    );
};

export default TextField;
