import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TextField } from '../index';
import { useAuth } from '../../context/AuthContext';

import './SignIn.scss';

const SignIn = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const response = await login(formData);

            if (response.success) {
                navigate('/home');
            } else {
                setError(`Login failed. ${response.error}`);
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred during login.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signin-component">
            <div className="form-container">
                {/* <div className='form-title'>
                    Welcome back!
                </div> */}
                <div className="form-sub-title">Sign In to Your Account</div>
                <form onSubmit={handleSubmit}>
                    <div className="form-item">
                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="text-field"
                            required
                        />
                    </div>
                    <div className="form-item">
                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            className="text-field"
                            required
                        />
                    </div>
                    {error && <div className="error-container">{error}</div>}
                    <div className="btn-container">
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ textTransform: 'none' }}
                            disabled={isLoading}
                            className="btn"
                        >
                            Sign In
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
