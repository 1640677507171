import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { Toast, TextField } from '../../index';

import './EditModal.scss';

const EditModal = ({ open, onClose, link }) => {
    const { user } = useAuth();
    const [formData, setFormData] = useState({
        title: link.title,
        slug: link.slug,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showDeleteView, setShowDeleteView] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const params = {
            title: formData.title,
        };

        try {
            const response = await axios.patch(
                `${apiBaseUrl}/link/${link.id}`,
                params,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            Toast.success('Link successfully updated!');
            onClose(true); // trigger the update call
        } catch (error) {
            console.error('update link error:', error);
            Toast.error('Problem updating link');
        } finally {
            setIsLoading(false);
        }
    };

    const handleModalClose = (event) => {
        // Check if the click event occurred outside the modal content
        if (event.target.classList.contains('link-action-modal')) {
            onCancel(); // Close the modal
        }
    };

    const onCancel = () => {
        // reset back to value passed in originally
        setFormData({
            title: link.title,
            slug: link.slug,
        });
        setShowDeleteView(false);
        onClose();
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);

        try {
            const response = await axios.delete(
                `${apiBaseUrl}/link/${link.id}`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            Toast.success('Link successfully deleted!');
            onClose(true); // trigger the update call
        } catch (error) {
            console.error('delete link error:', error);
            Toast.error('Problem deleting link');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose} onClick={handleModalClose}>
            <div className="link-action-modal">
                {!showDeleteView ? (
                    <div className="modal-content">
                        <div className="modal-header-container">
                            <div className="modal-header">Edit Link</div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="modal-close-btn"
                                    onClick={onCancel}
                                />
                            </div>
                        </div>

                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="form-item">
                                    <TextField
                                        id="title"
                                        label="Title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        className="text-field"
                                    />
                                </div>

                                <div className="form-item flex">
                                    <TextField
                                        id="domain"
                                        label="Domain"
                                        name="domain"
                                        value={'lemr.link'}
                                        className="text-field"
                                        disabled
                                    />
                                    <TextField
                                        id="back-half"
                                        label="Back-Half"
                                        name="back-half"
                                        value={formData.slug}
                                        onChange={handleInputChange}
                                        className="text-field"
                                        disabled
                                    />
                                </div>

                                <div className="btn-container">
                                    <div className="delete-btn">
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() =>
                                                setShowDeleteView(true)
                                            }
                                            style={{
                                                textTransform: 'none',
                                            }}
                                            disabled={isLoading}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                    <Button
                                        onClick={onCancel}
                                        variant="outlined"
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{
                                            textTransform: 'none',
                                        }}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="modal-content">
                        <div className="modal-header-container">
                            <div className="modal-header">Confrim Deletion</div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="modal-close-btn"
                                    onClick={onClose}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="delete-text">
                                Are you sure you want to delete{' '}
                                {link.title ? `"${link.title}"` : 'this link'}?
                                This action cannot be undone.
                            </div>
                            <div className="btn-container-delete">
                                <Button
                                    onClick={() => setShowDeleteView(false)}
                                    variant="outlined"
                                    style={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleConfirmDelete}
                                    variant="contained"
                                    color="error"
                                    style={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default EditModal;
