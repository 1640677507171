import { useState } from 'react';
import axios from 'axios';
import {
    Elements,
    LinkAuthenticationElement,
    PaymentElement,
    AddressElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Button, Modal } from '@mui/material';
import { Toast } from '../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCreditCard,
    faUser,
    faFile,
    faCartShopping,
    faFileContract,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import './Checkout.scss';

const PaymentForm = ({
    defaultEmail,
    onSuccess,
    defaultName,
    selectedPlan,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(defaultEmail);
    const [name, setName] = useState(defaultName);
    const [customerId, setCustomerId] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState('');

    const CustomerTypes = {
        stripe: 'stripe',
    };

    const LoadingStates = {
        none: {
            message: '',
            icon: faSpinner,
        },
        card: {
            message: 'Validating card details',
            icon: faCreditCard,
        },
        profile: {
            message: 'Creating customer profile',
            icon: faUser,
        },
        subscription: {
            message: 'Setting up subscription',
            icon: faFile,
        },
        payment: {
            message: 'Initiating payment',
            icon: faCartShopping,
        },
        finalizing: {
            message: 'Finalizing details',
            icon: faFileContract,
        },
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            // Trigger form validation and wallet collection
            setLoadingState('card');
            const { error: submitError } = await elements.submit();
            if (submitError) {
                // console.log('problem validating elements');
                // // handleError(submitError);
                setIsLoading(false);
                setLoadingState('none');
                return;
            }

            setLoadingState('profile');
            const customerReponse = await axios.post(
                `${apiBaseUrl}/payment/create-customer`,
                {
                    name,
                    email,
                    address,
                    phone,
                }
            );

            setLoadingState('subscription');
            const subscriptionReponse = await axios.post(
                `${apiBaseUrl}/payment/create-subscription`,
                {
                    customerId: customerReponse.data.customerId,
                    planId: selectedPlan.id,
                }
            );

            const { type, clientSecret } = subscriptionReponse.data;

            const confirmIntent =
                type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

            // this is where the payment is made
            setLoadingState('payment');
            const { error } = await confirmIntent({
                elements,
                clientSecret,
                redirect: 'if_required',
                confirmParams: {
                    return_url: 'https://lemurlinks.com/signin',
                },
            });

            if (error) {
                console.error(error);
                setIsLoading(false);
                setLoadingState('none');
                Toast.error('Problem completing payment');
            } else {
                setLoadingState('finalizing');
                onSuccess({
                    address,
                    phone,
                    customer_type: CustomerTypes.stripe,
                    customer_id: customerReponse.data.customerId,
                });
            }
        } catch (error) {
            console.error(error);
            Toast.error('Problem completing payment');
            setIsLoading(false);
            setLoadingState('none');
        }
    };

    const handleAddressChange = (event) => {
        setAddress(event.address);
        setPhone(event.phone);
        setName(event.name);
    };

    const handleEmailChange = (event) => {
        setEmail(event.value.email);
    };

    return (
        <div>
            {isLoading && (
                <Modal open={isLoading}>
                    <div className="checkout-modal">
                        <div className="modal-content">
                            <div>Please do not refresh your browser</div>
                            <div className="loading-message animate">
                                <div>
                                    <FontAwesomeIcon
                                        icon={LoadingStates[loadingState].icon}
                                        className="icon"
                                    />
                                </div>
                                <div>{LoadingStates[loadingState].message}</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <div>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '10px' }}>
                        <LinkAuthenticationElement
                            onChange={(event) => {
                                handleEmailChange(event);
                            }}
                            options={{
                                defaultValues: { email },
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <AddressElement
                            options={{
                                mode: 'billing',
                                defaultValues: { name },
                                fields: {
                                    phone: 'always',
                                },
                            }}
                            onChange={(event) => {
                                handleAddressChange(event.value);
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '40px', marginTop: '40px' }}>
                        <h3>Payment</h3>
                        <PaymentElement />
                    </div>
                    <div className="btn-container payment-btn">
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ textTransform: 'none' }}
                            disabled={false}
                            className="btn"
                        >
                            Complete Purchase
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Customize the appearance of Elements using the Appearance API.
const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#333',
        colorDanger: '#df1b41',
        fontFamily: 'Roboto, Arial, sans-serif',
        spacingUnit: '4px',
        borderRadius: '4px',
        gridRowSpacing: '20px',
    },
};

const Checkout = ({ stripePromise, email, name, onSuccess, selectedPlan }) => {
    const options = {
        mode: 'subscription',
        amount: selectedPlan.amount || 0,
        currency: 'usd',
        appearance,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentForm
                defaultEmail={email}
                onSuccess={onSuccess}
                defaultName={name}
                selectedPlan={selectedPlan}
            />
        </Elements>
    );
};

export { Checkout };
