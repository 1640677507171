import { Select as MUISelect, MenuItem, InputLabel } from '@mui/material';

const Select = ({ label, placeholder, disabled, options, ...rest }) => {
    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <InputLabel
                shrink
                style={{
                    fontSize: '21px',
                    fontWeight: '500',
                    color: disabled ? '#AAA' : '#333',
                    marginBottom: '-2px',
                }}
            >
                {label}
            </InputLabel>
            <MUISelect
                displayEmpty
                defaultValue=""
                {...rest}
                style={{ width: '100%' }}
                sx={{
                    '& .MuiSelect-select': {
                        paddingRight: '14px',
                        paddingLeft: '14px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    },
                }}
            >
                {placeholder && (
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                )}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MUISelect>
        </div>
    );
};

export default Select;
