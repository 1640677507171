import './Support.scss';

const Support = () => {
    return (
        <div className="support-container">
            <div className="main-content">
                <div className="support-header">Support</div>
                <div className="card support-items">
                    <div className="support-message">
                        If you have questions or need help, please email the
                        appropriate channel below and we will respond as soon as
                        possible.
                    </div>
                    <div>
                        <div className="support-item-header">
                            General Support
                        </div>
                        <div>support@lemurlinks.com</div>
                    </div>
                    <div>
                        <div className="support-item-header">
                            Billing Support
                        </div>
                        <div>billing@lemurlinks.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
