import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './PieChart.scss';

const colorMap = {
    Desktop: '#6699CC',
    Mobile: '#2E8B57',
    Tablet: '#FFB347',
    'E-Reader': '#BA55D3',
    Unknown: '#FF6347',
};

// other style and customization
const PieChartComponent = (props) => {
    const { data, isLoading } = props;
    const chartData = data.map((linkClick) => {
        return {
            name: linkClick.mapped_device,
            count: linkClick.count,
            fill: colorMap[linkClick.mapped_device],
        };
    });

    return (
        <div className="pie-chart-container">
            {!isLoading && data.length > 0 && (
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            dataKey="count"
                            data={chartData}
                            fill="fill"
                            label
                        />
                        {/* <Pie
                            data={chartData}
                            dataKey="count"
                            nameKey="name"
                            innerRadius={80}
                            outerRadius={100}
                            fill="#004e75"
                            label
                        /> */}
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            )}
            {!isLoading && data.length == 0 && (
                <div className="no-data">No Device data</div>
            )}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default PieChartComponent;
