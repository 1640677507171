import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { TextField } from '../index';
import LemurIcon from './lemur_icon.png';
import AnalyticsDemo from './analytics_demo.png';
import { usePlans } from '../../context/PlanContext';
import './Main.scss';

const Main = () => {
    // these are not used in this component
    // just improves UX to have these loaded in, in the event they navidate to /plans or /signup
    // to not have to wait for them to load the first time
    const { plans, fetchPlans } = usePlans();
    useEffect(() => {
        if (!plans.length) {
            fetchPlans();
        }
    }, [plans, fetchPlans]);

    return (
        <div className="main-component">
            <div className="main-content">
                <div className="header-block">
                    <div className="head-text-container">
                        <div className="header">
                            Make Links <span>Matter</span>
                        </div>
                        <div className="description">
                            Your all-in-one link management solution. Create,
                            edit, and track links with ease. From URL
                            customization to click tracking, we've got you
                            covered. Start gaining meaningful insights into your
                            links today.
                        </div>
                        <div className="btn-container">
                            <Link to="/signup">
                                <Button
                                    variant="contained"
                                    style={{ textTransform: 'none' }}
                                    className="btn"
                                >
                                    Get Started for Free
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="img-container">
                        <img
                            src={LemurIcon}
                            className="lemur-img"
                            alt="Lemur Links Logo"
                        />
                    </div>
                </div>
                <div className="about-block">
                    <div className="about-content">
                        <div className="about-description">
                            <div className="about-description-block">
                                Lemur Links is the premier link management tool.
                            </div>
                            <div>
                                Lemur Links empowers you to efficiently manage
                                your digital footprint and streamline your
                                online activity. Our intuitive Connection Portal
                                allows you to customize links, track clicks and
                                user information (location, device types and
                                more), URL shortening capabilities, all of which
                                allow you to optimize your online performance
                                with ease.
                            </div>
                        </div>
                        <div className="about-header">What is Lemur Links?</div>
                    </div>
                </div>
                <div className="analytics-block">
                    <div className="analytics-content">
                        <div className="analytics-header">
                            Detailed Analytics at your fingertips
                        </div>
                        <div className="analytics-description">
                            Gain insightful details into your clicks including
                            user device type, location and more
                        </div>
                        <div className="img-container">
                            <img
                                src={AnalyticsDemo}
                                className="analytics-img"
                                alt="Lemur Links Analytics"
                            />
                        </div>
                    </div>
                </div>
                <div className="example-block">
                    <div className="example-header">
                        Unlock insights with every click
                    </div>
                    <div className="example-sub-header">
                        Start optimizing your links for free with Lemur Links
                    </div>
                    <div className="form-header">Shorten a long link</div>
                    <div className="form-item">
                        <TextField
                            id="destinationUrl"
                            label="Enter/paste a long URL"
                            placeholder="Example: http://super-long-link.com/shorten-it"
                            name="destinationUrl"
                            className="text-field"
                        />
                    </div>
                    <div className="last-item">
                        <div className="domain">
                            <TextField
                                id="domain"
                                label="Domain"
                                name="domain"
                                value="lemr.link"
                                disabled={true}
                                className="text-field"
                            />
                        </div>
                        <div className="divider"> / </div>
                        <div className="back-half">
                            <TextField
                                id="slug"
                                label="Enter a back-half (optional)"
                                name="slug"
                                className="text-field"
                            />
                        </div>
                    </div>
                    <div className="form-footer">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="footer-icon"
                        />{' '}
                        Customize your link ending to make it unique
                    </div>
                    <div className="btn-container">
                        <Link to="/signup">
                            <Button
                                variant="contained"
                                style={{ textTransform: 'none' }}
                                className="btn"
                            >
                                Sign up and get your link
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
