import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faSpinner,
    faLinkSlash,
} from '@fortawesome/free-solid-svg-icons';
import { LinkCard } from '../index';
import { useAuth } from '../../context/AuthContext';
import { Analytics, Toast } from '../index';

import './LinkDetails.scss';

const LinkDetails = () => {
    const { user } = useAuth();
    const { slug } = useParams();
    const [link, setLink] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [showNotFound, setShowNotFound] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchLinkBySlug = async (slug) => {
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiBaseUrl}/link/${slug}`, {
                headers: {
                    Authorization: user ? `Bearer ${user.token}` : '',
                },
            });
            setLink(response.data.link);
        } catch (error) {
            // link not found or no perm
            // reset link and show message
            if (error.response.status === 404) {
                setLink(null);
                setShowNotFound(true);
            } else {
                Toast.error('An error occurred fetching link.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdate = () => {
        fetchLinkBySlug(slug);
    };

    useEffect(() => {
        fetchLinkBySlug(slug);
    }, [slug]);

    return (
        <div className="link-details-container">
            <div className="main-content">
                <div className="link-container">
                    <Link to="/links">
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="link-icon"
                        />
                        Back to list
                    </Link>
                </div>
                {isLoading && (
                    <div className="card loading-container">
                        <div className="loader">
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="fa-spin icon"
                            />
                        </div>
                    </div>
                )}
                {!isLoading && link && (
                    <div>
                        <LinkCard link={link} onUpdate={onUpdate} />
                        <div className="detail-analytics-container">
                            <Analytics
                                linkId={link.id}
                                charts={{
                                    aggregate: true,
                                    byDevice: true,
                                    byLocation: true,
                                    overTimeFullWidth: true,
                                    aggregateShowUniqueLinks: false,
                                }}
                                startDate={link.created_at}
                            />
                        </div>
                    </div>
                )}
                {!isLoading && showNotFound && (
                    <div className="card bad-link">
                        <FontAwesomeIcon
                            icon={faLinkSlash}
                            className="bad-link-icon"
                        />{' '}
                        Link does not exist or you do not have permission to
                        view it
                    </div>
                )}
            </div>
        </div>
    );
};

export default LinkDetails;
