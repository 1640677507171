import React, { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';

import './RouteStyles.scss';

const ProtectedRoute = () => {
    const { user, loading, authenticate } = useAuth();
    const location = useLocation();

    // if user is logged in, authenticate on protected route changes
    useEffect(() => {
        if (user) {
            authenticate();
        }
    }, [location, user]);

    if (loading) {
        return (
            <div className="route-loader">
                <div className="content">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            </div>
        );
    }

    return user ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoute;
