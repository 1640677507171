import './TermsOfService.scss';

const TermsOfService = () => {
    return (
        <div className="terms-of-service">
            <div className="main-content">
                <h1>Lemur Links Terms of Service</h1>
                <div>
                    Welcome to Lemur Links! These terms of service ("Terms")
                    govern your use of the Lemur Links website, services, and
                    tools (collectively referred to as the "Service"). By
                    accessing or using the Service, you agree to be bound by
                    these Terms.
                </div>
                <ol>
                    <li>
                        <span className="list-head">Acceptance of Terms</span>:
                        By using the Service, you agree to these Terms. If you
                        do not agree with these Terms, please do not use the
                        Service.
                    </li>
                    <li>
                        <span className="list-head">
                            Description of Service
                        </span>
                        : Lemur Links provides a link management tool that
                        allows users to shorten URLs, track link analytics, and
                        manage their links.
                    </li>
                    <li>
                        <span className="list-head">User Accounts</span>: To
                        access certain features of the Service, you may need to
                        create a user account. You are responsible for
                        maintaining the security of your account credentials and
                        for all activities that occur under your account.
                    </li>
                    <li>
                        <span className="list-head">Link Creation and Use</span>
                        : By using the Service, you agree not to create or share
                        links that violate any laws or infringe on the rights of
                        others. Lemur Links reserves the right to disable any
                        links that violate these Terms.
                    </li>
                    <li>
                        <span className="list-head">Privacy</span>: Your privacy
                        is important to us. Please review our Privacy Policy to
                        understand how we collect, use, and disclose
                        information.
                    </li>
                    <li>
                        <span className="list-head">Intellectual Property</span>
                        : The Service and its content, including but not limited
                        to text, graphics, logos, and software, are owned or
                        licensed by Lemur Links and are protected by copyright
                        and other intellectual property laws.
                    </li>
                    <li>
                        <span className="list-head">
                            Limitation of Liability
                        </span>
                        : Lemur Links is not liable for any damages or losses
                        arising out of your use of the Service. In no event
                        shall Lemur Links be liable for any indirect,
                        incidental, special, or consequential damages.
                    </li>
                    <li>
                        <span className="list-head">Indemnification</span>: You
                        agree to indemnify and hold Lemur Links harmless from
                        any claims, losses, liabilities, and expenses arising
                        out of your use of the Service or your violation of
                        these Terms.
                    </li>
                    <li>
                        <span className="list-head">
                            Modifications to Terms
                        </span>
                        : Lemur Links reserves the right to modify these Terms
                        at any time. Changes will be effective upon posting to
                        the Service. Your continued use of the Service after
                        changes are posted constitutes your acceptance of the
                        modified Terms.
                    </li>
                    <li>
                        <span className="list-head">Termination</span>: Lemur
                        Links may terminate or suspend your access to the
                        Service at any time, with or without cause, and without
                        notice.
                    </li>
                    <li>
                        <span className="list-head">Governing Law</span>: These
                        Terms shall be governed by and construed in accordance
                        with the laws of the United States, without regard to
                        its conflict of law provisions.
                    </li>
                </ol>
                <div className="thank-you">
                    Thank you for using Lemur Links!
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
