import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
    faInstagram,
    faYoutube,
    faFacebook,
    faLinkedin,
    faTiktok,
    faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

const ProfileView = ({ setView, onClose }) => {
    const onCancel = () => {
        onClose();
    };

    return (
        <>
            <div className="modal-header-container">
                <div>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="modal-close-btn"
                        onClick={() => setView('share')}
                    />
                </div>
                <div className="modal-header">Add link to your socials</div>
                <div>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="modal-close-btn"
                        onClick={onCancel}
                    />
                </div>
            </div>
            <div className="modal-body profile-body">
                <div className="description">
                    Share on your profile and get more clicks
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('instagram')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faInstagram}
                            className="social-icon"
                            style={{ color: '#C13584' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">Instagram</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('youtube')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faYoutube}
                            className="social-icon"
                            style={{ color: '#FF0000' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">YouTube</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('tiktok')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faTiktok}
                            className="social-icon"
                            style={{ color: '#000' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">TikTok</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('facebook')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faFacebook}
                            className="social-icon"
                            style={{ color: '#1877F2' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">Facebook</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('twitter')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faXTwitter}
                            className="social-icon"
                            style={{ color: '#000' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">X (formerly Twitter)</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('linkedin')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            className="social-icon"
                            style={{ color: '#0077B5' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">LinkedIn</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileView;
