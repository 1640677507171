import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faCopy,
    faArrowLeft,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip } from '@mui/material';
import sharingSteps from './sharingSteps';

const SocialView = ({ setView, link, onClose, social }) => {
    const [copied, setCopied] = useState(false);
    const [selectedSocial, setSelectedSocial] = useState({
        prettyName: '',
        link: '',
        steps: [],
    });

    const onCancel = () => {
        onClose();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(link.url);
        setTimeout(() => {
            setCopied(true);
        }, 300);
    };

    const toolTipClosed = () => {
        setCopied(false);
    };

    useEffect(() => {
        setSelectedSocial(sharingSteps[social]);
    }, [social]);

    return (
        <>
            <div className="modal-header-container">
                <div>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="modal-close-btn"
                        onClick={() => setView('profiles')}
                    />
                </div>
                <div className="modal-header">
                    Add to {selectedSocial.prettyName}
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="modal-close-btn"
                        onClick={onCancel}
                    />
                </div>
            </div>
            <div className="modal-body">
                <div className="description">
                    Follow the steps below to add your LemurLink to{' '}
                    {selectedSocial.prettyName}
                </div>
                <ol className="social-steps">
                    {selectedSocial.steps.map((step, index) => {
                        return (
                            <li key={index}>
                                <span className="step-header">
                                    {step.header}:{' '}
                                </span>
                                <span className="step-body">{step.body}</span>
                            </li>
                        );
                    })}
                </ol>
            </div>
            <div className="url-container">
                <div className="url">{link.url}</div>
                <div className="link-action-btn-container">
                    <Tooltip
                        title="Copied!"
                        onClose={toolTipClosed}
                        open={copied}
                        leaveDelay={2000}
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: '#2E8B57',
                                    color: 'white',
                                    fontSize: '14px',
                                },
                            },
                        }}
                    >
                        <Button
                            variant="outlined"
                            className="link-action-btn"
                            onClick={handleCopy}
                        >
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="btn-icon"
                            />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className="btn-container">
                <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    className="social-btn"
                    component="a"
                    href={selectedSocial.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Open {selectedSocial.prettyName}
                    <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="social-link-out"
                    />
                </Button>
            </div>
            {/* <div className="modal-body profile-body">
                <div className="description">
                    Share on your profile and get more clicks
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('instagram')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faInstagram}
                            className="social-icon"
                            style={{ color: 'pink' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">Instagram</div>
                    </div>
                </div>
                <div
                    className="social-container"
                    onClick={() => setView('youtube')}
                >
                    <div className="main-social-icon">
                        <FontAwesomeIcon
                            icon={faYoutube}
                            className="social-icon"
                            style={{ color: 'red' }}
                        />
                    </div>
                    <div className="social-header-container">
                        <div className="socal-header">YouTube</div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default SocialView;
