import React, { useState } from 'react';
import { Modal } from '@mui/material';

import ShareView from './Views/ShareView';
import ProfileView from './Views/ProfileView';
import SocialView from './Views/SocialView';

import './ShareModal.scss';

const ShareModal = ({ open, onClose, link }) => {
    const socials = [
        'instagram',
        'youtube',
        'twitter',
        'facebook',
        'linkedin',
        'tiktok',
    ];
    const Views = {
        share: 'share',
        profiles: 'profiles',
        social: 'social',
        followers: 'followers',
    };

    const [view, setView] = useState(Views.share);
    const [social, setSocial] = useState('');

    const handleModalClose = (event) => {
        // Check if the click event occurred outside the modal content
        if (event.target.classList.contains('link-action-modal')) {
            handleClose();
        }
    };

    const handleClose = () => {
        setView(Views.share);
        onClose(); // Close the modal
    };

    const updateView = (view) => {
        if (socials.includes(view)) {
            setView('social');
            setSocial(view);
        } else {
            setView(view);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} onClick={handleModalClose}>
            <div className="link-action-modal">
                <div className="modal-content">
                    {view === Views.share && (
                        <ShareView
                            link={link}
                            setView={updateView}
                            onClose={handleClose}
                        />
                    )}
                    {view === Views.profiles && (
                        <ProfileView
                            setView={updateView}
                            onClose={handleClose}
                        />
                    )}
                    {view === Views.social && (
                        <SocialView
                            link={link}
                            setView={updateView}
                            onClose={handleClose}
                            social={social}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
