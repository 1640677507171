import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TextField } from '@mui/material';

import './DateRangePicker.scss';

const DateRangePicker = (props) => {
    const minDate = props.minDate || '2024-01-01'; // you should always pass a min date in, this is fallback
    const maxDate = props.maxDate || moment().format('YYYY-MM-DD'); // today
    const [startDate, setStartDate] = useState(props.startDate || minDate);
    const [endDate, setEndDate] = useState(props.endDate || maxDate);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        props.onChange({ startDate: event.target.value, endDate });
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        props.onChange({ startDate, endDate: event.target.value });
    };

    useEffect(() => {
        setStartDate(props.startDate || minDate);
        setEndDate(props.endDate || maxDate);
    }, [props]);

    return (
        <div className="date-range-container">
            <TextField
                id="start-date"
                label="Start Date"
                type="date"
                variant="standard"
                value={startDate}
                className="text-field"
                onChange={handleStartDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputProps: {
                        min: minDate,
                        max: endDate,
                    },
                    style: {
                        textAlign: 'left',
                    },
                }}
            />
            <div className="separator">-</div>
            <TextField
                id="end-date"
                label="End Date"
                type="date"
                variant="standard"
                value={endDate}
                className="text-field"
                onChange={handleEndDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputProps: {
                        min: startDate,
                        max: maxDate,
                    },
                    style: {
                        textAlign: 'left',
                    },
                }}
            />
        </div>
    );
};

export default DateRangePicker;
