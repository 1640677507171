import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';
import { Aggregate, Toast } from '../index';
import LemurIcon from './lemur_icon.png';

import './HomePage.scss';

const HomePage = () => {
    const { user } = useAuth();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [aggregateDataLoading, setAggregateDataLoading] = useState(true);
    const [aggregateData, setAggregateData] = useState([]);
    const today = moment();
    const oneWeekAgo = moment().subtract(7, 'days');

    // fetch aggregate data
    const fetchAggregateData = async (params) => {
        setAggregateDataLoading(true);

        const paramsToSend = {
            ...params,
        };

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/aggregate`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: paramsToSend,
                }
            );
            // console.log('reposnse', response.data.linkClicks);
            // j-todo: make sure this exists?
            setAggregateData(response.data.linkClicks);
        } catch (error) {
            console.error('Fetch aggregate data error:', error);
            Toast.error('Problem fetching aggregate data');
        } finally {
            setAggregateDataLoading(false);
        }
    };

    useEffect(() => {
        fetchAggregateData({
            startDate: oneWeekAgo.format('YYYY-MM-DD'),
            endDate: today.format('YYYY-MM-DD'),
        });
    }, []);

    return (
        <div className="home-container">
            <div className="main-content">
                <div className="card">
                    <div className="img-container">
                        <img
                            src={LemurIcon}
                            className="lemur-img"
                            alt="Lemur Links Logo"
                        />
                    </div>
                    <div className="header">Welcome, {user.first_name}!</div>
                    <div className="aggregate-header">Week Snapshot</div>
                    <div className="aggregate-container">
                        <Aggregate
                            isLoading={aggregateDataLoading}
                            data={aggregateData}
                            dateRange={{
                                startDate: oneWeekAgo,
                                endDate: today,
                            }}
                            aggregateShowUniqueLinks={true}
                        />
                    </div>
                    <div>
                        <div className="btn-container">
                            <div className="btn-header">
                                Ready to get more clicks?
                            </div>
                            <Link to="/link/create">
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: 'none',
                                    }}
                                    className="btn"
                                >
                                    Create a Link
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
