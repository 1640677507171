import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './BarChart.scss';

const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {payload[0].payload.title && (
                    <div className="title">{`${payload[0].payload.title}`}</div>
                )}
                <div className="label">{`${payload[0].payload.link}`}</div>
                <div className="clicks">
                    <span className="dot"></span>
                    {`Clicks: ${payload[0].value}`}
                </div>
            </div>
        );
    }

    return null;
};

// j-todo: truncate long xLabels and in tooltip...
// figure out bar color
// set max width for bar, small amounts look funny
const BarChartComponent = (props) => {
    const { isLoading, data } = props;
    const barColor = '#6699CC';

    return (
        <div className="bar-chart-container">
            {!isLoading && data.length > 0 && (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={data}
                        margin={{
                            bottom: 80,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="xLabel"
                            angle={-45}
                            textAnchor="end"
                            interval={0}
                            tick={{ fontSize: 14 }}
                        />
                        <YAxis tick={{ fontSize: 14 }} allowDecimals={false} />
                        <Tooltip content={<CustomToolTip />} />
                        {/* <Legend /> */}
                        <Bar dataKey="count" fill={barColor} />
                    </BarChart>
                </ResponsiveContainer>
            )}
            {!isLoading && data.length == 0 && (
                <div className="no-data">No Link data</div>
            )}
            {isLoading && (
                <div className="loader">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            )}
        </div>
    );
};

export default BarChartComponent;
