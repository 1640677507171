import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Toast, TextField } from '../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

import './LinksCreate.scss';

// j-todo:
// for analytics
// enforce the date range from date from user.actionLimits.analyticsPeriod
// maybe free cant change at all?
// disable filters
// think through
// j-todo: handle when count is 1
const LinksCreate = () => {
    const { user, updateUser } = useAuth();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        destinationUrl: '',
        title: '',
        slug: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [slugError, setSlugError] = useState(null);
    const [destinationUrlError, setDestinationUrlError] = useState('');

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const formatUrl = (inputUrl) => {
        // Add "http://" if the URL doesn't start with it
        return inputUrl.startsWith('http://') || inputUrl.startsWith('https://')
            ? inputUrl
            : `http://${inputUrl}`;
    };

    const isValidUrl = (url) => {
        // Regular expression to validate URL
        const urlPattern =
            /^(ftp|http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
        return urlPattern.test(url);
    };

    // on blur of destination url, validate the url and format it
    // if we have a valid formatted url, attempt to fetch the page title
    // if it succeeds set the title form field, otherwise just update the url
    const attemptTitleFetch = async () => {
        const url = formData.destinationUrl.trim();

        if (url) {
            const formattedUrl = formatUrl(url);

            if (isValidUrl(formattedUrl)) {
                setFormData({
                    ...formData,
                    destinationUrl: formattedUrl,
                });
                setDestinationUrlError('');

                const fetchedTitle = await fetchPageTitle(formattedUrl);

                if (fetchedTitle) {
                    setFormData({
                        ...formData,
                        destinationUrl: formattedUrl, // reset just in case it wasnt complete
                        title: fetchedTitle,
                    });
                }

                return {
                    success: true,
                    title: fetchedTitle || '',
                    destinationUrl: formattedUrl,
                };
            } else {
                setDestinationUrlError(
                    `We require a valid URL, such as "yourbrand.com/exampleurl"`
                );
            }
        } else {
            setDestinationUrlError('URL is required');
        }

        return {
            success: false,
        };
    };

    // if the title hasnt been set yet,
    // try setting it once we formatted the url
    // if it fails we'll set it on save to base url
    const fetchPageTitle = async (url) => {
        if (!formData.title.trim()) {
            try {
                const response = await axios.get(
                    `${apiBaseUrl}/cheerio/get-title`,
                    {
                        headers: {
                            Authorization: user ? `Bearer ${user.token}` : '',
                        },
                        params: {
                            url,
                        },
                    }
                );

                if (response.data.title) {
                    return response.data.title;
                }
            } catch (error) {} // ignore
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // if any errors are showing prevent submit
    const formIsInvalidValid = () => {
        return !!destinationUrlError || !!slugError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formIsInvalidValid()) {
            return;
        }

        setIsLoading(true);

        // we might manipulate title/url and dont need the form data anymore
        const data = formData;

        if (!data.title) {
            const result = await attemptTitleFetch();
            if (!result.success) {
                setIsLoading(false);
                return;
            } else {
                data.destinationUrl = result.destinationUrl;
                data.title = result.title;
            }
        }

        // if we still dont have a title on save, we'll just set it to base url
        if (!data.title.trim()) {
            try {
                const parsedUrl = new URL(data.destinationUrl);
                const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
                data.title = baseUrl;
            } catch (error) {}
        }

        try {
            const response = await axios.post(
                `${apiBaseUrl}/link`,
                {
                    ...data,
                },
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                }
            );

            // success call so decrease remaining actions
            const remainingActions = { ...user.remainingActions };
            remainingActions.remainingLinks--;
            if (formData.slug) {
                remainingActions.remainingCustomLinks--;
            }
            updateUser({ remainingActions });

            // nav to details
            navigate(`/link/details/${response.data.link.slug}`);
        } catch (error) {
            console.error('create link error:', error);
            if (error.response.data.code === 'duplicate_slug') {
                setSlugError('Back half is already in use');
            } else {
                Toast.error('Problem creating link');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="link-create-component">
            <div className="form-container">
                {user.remainingActions.remainingLinks === 0 && (
                    <div className="no-remaining-container">
                        <div className="no-remaining-header">
                            <FontAwesomeIcon icon={faUserShield} /> You have
                            used up all of your links for the month (
                            {user.actionLimits.linksCreatedPerMonth}).
                        </div>
                        <div>
                            Upgrade your plan to increase your monthly limit.
                        </div>
                    </div>
                )}
                <div className="form-title">Create New</div>
                <form onSubmit={handleSubmit}>
                    <div className="form-item">
                        <TextField
                            id="destinationUrl"
                            label="Destination"
                            name="destinationUrl"
                            value={formData.destinationUrl}
                            onChange={handleInputChange}
                            onBlur={attemptTitleFetch}
                            className="text-field"
                            error={!!destinationUrlError}
                            helperText={destinationUrlError}
                            required
                            disabled={
                                user.remainingActions.remainingLinks === 0
                            }
                        />
                        {user.remainingActions.remainingLinks > 0 && (
                            <div className="remaining-actions">
                                You can create{' '}
                                <span className="remaining">
                                    {user.remainingActions.remainingLinks}
                                </span>{' '}
                                more links this month
                            </div>
                        )}
                    </div>
                    <div className="form-item">
                        <TextField
                            id="title"
                            label="Title (optional)"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="text-field"
                            disabled={
                                user.remainingActions.remainingLinks === 0
                            }
                        />
                    </div>
                    <div className="form-sub-header">Ways to share</div>
                    <div className="ways-to-share">
                        <div className="domain-container">
                            <TextField
                                id="domain"
                                label="Domain"
                                name="domain"
                                value="lemr.link"
                                disabled={true}
                            />
                        </div>
                        <div className="divider"> / </div>
                        <div className="custom-slug">
                            <TextField
                                id="slug"
                                label="Custom back-half (optional)"
                                name="slug"
                                value={formData.slug}
                                onChange={handleInputChange}
                                error={!!slugError} // Set error state based on passwordError
                                helperText={slugError} // Display error message
                                disabled={
                                    user.remainingActions
                                        .remainingCustomLinks === 0
                                }
                            />
                        </div>
                    </div>
                    {user.remainingActions.remainingLinks > 0 && (
                        <div className="remaining-actions">
                            {user.remainingActions.remainingCustomLinks > 0 ? (
                                <>
                                    You can create{' '}
                                    <span className="remaining">
                                        {
                                            user.remainingActions
                                                .remainingCustomLinks
                                        }
                                    </span>{' '}
                                    more custom back-halves this month
                                </>
                            ) : (
                                <div className="no-custom-remaining">
                                    <div className="no-custom-header">
                                        You have used up all of your custom
                                        back-halves for the month (
                                        {
                                            user.actionLimits
                                                .customBackHalvesPerMonth
                                        }
                                        ).
                                    </div>
                                    <div>
                                        Upgrade your plan to increase your
                                        monthly limit.
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="btn-container">
                        <div>
                            <Link to="/links">
                                <Button
                                    variant="secondary"
                                    style={{ textTransform: 'none' }}
                                >
                                    Cancel
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                style={{ textTransform: 'none' }}
                                disabled={
                                    user.remainingActions.remainingLinks ===
                                        0 || isLoading
                                }
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LinksCreate;
