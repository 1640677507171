import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';

import './RouteStyles.scss';

const PublicRoute = () => {
    const { user, loading } = useAuth();
    const queryParams = new URLSearchParams(window.location.search);
    // showInApp flag allows public routes to be displayed in app (like TOS)
    // rather than force navigating back to home
    const showInApp = queryParams.get('showInApp');

    if (loading) {
        return (
            <div className="route-loader">
                <div className="content">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon"
                    />
                </div>
            </div>
        );
    }

    return !user || showInApp ? <Outlet /> : <Navigate to="/home" />;
};

export default PublicRoute;
