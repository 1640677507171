import React, { useState, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';
import { LinkActions, TextField } from '../index';
import FilterModal from './FilterModal/FilterModal';
import { useAuth } from '../../context/AuthContext';
import './LinksTable.scss';

// onUpdate passed in for link actions
const LinksTable = ({ data, onUpdate }) => {
    const { user } = useAuth();
    const LinkTypes = {
        all: 0,
        custom: 1,
        notCustom: 2,
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [linkType, setLinkType] = useState(LinkTypes.all);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    // when the user was created, no point in using before for showing links
    const defaultStartDate = moment(user.created_at).format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD'); // today
    const [dateRange, setDateRange] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // search title, url and destination if searchterm is present
    const filterItemBySearchTerm = (item) => {
        return (
            item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.url.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.destinationUrl.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // if date range has changed
    // check that created_at (not formatted) is withtin the date range
    const filterItemByCreatedAt = (item) => {
        return (dateRange.startDate !== defaultStartDate ||
            dateRange.endDate !== defaultEndDate) &&
            dateRange.startDate &&
            dateRange.endDate
            ? moment(item.created_at).isBetween(
                  dateRange.startDate,
                  dateRange.endDate,
                  null,
                  '[]'
              )
            : true; // If no date range is specified, consider all items
    };

    // if linktype is all return true
    // otherwise check linktype vs item linktype
    const filterItemByLinkType = (item) => {
        return (
            linkType === LinkTypes.all ||
            (linkType === LinkTypes.custom && item.isCustom) ||
            (linkType === LinkTypes.notCustom && !item.isCustom)
        );
    };

    const sortedData = useMemo(() => {
        // Apply filtering logic first
        const filteredData = data.filter((item) => {
            const createdAtInRange = filterItemByCreatedAt(item);
            const isLinkTypeMatch = filterItemByLinkType(item);
            const matchesSearchTerm = filterItemBySearchTerm(item);

            // Return true only if all conditions are met
            return matchesSearchTerm && createdAtInRange && isLinkTypeMatch;
        });

        // ordering
        if (orderBy && order) {
            return filteredData.slice().sort((a, b) => {
                const valueA = a[orderBy];
                const valueB = b[orderBy];

                // Check if the values are numbers
                if (!isNaN(valueA) && !isNaN(valueB)) {
                    // If both values are numbers, compare them as numbers
                    return order === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (
                    typeof valueA === 'string' &&
                    typeof valueB === 'string'
                ) {
                    // If both values are strings, compare them using localeCompare
                    return order === 'asc'
                        ? valueA.localeCompare(valueB)
                        : valueB.localeCompare(valueA);
                } else {
                    // If one of the values is a date, you can convert them to Date objects and compare
                    const dateA = new Date(valueA);
                    const dateB = new Date(valueB);
                    return order === 'asc' ? dateA - dateB : dateB - dateA;
                }
            });
        }

        return filteredData;
    }, [data, orderBy, order, searchTerm, linkType, dateRange]);

    const handleRowClick = (row) => {
        navigate(`/link/details/${row.slug}`);
    };

    const onApply = (values) => {
        setLinkType(values.linkType);
        setDateRange(values.dateRange);
        setFilterModalOpen(false);
    };

    return (
        <div className="link-table-component">
            {/* filters  */}
            <div className="filters-container">
                <div className="filter-item">
                    <TextField
                        label="Search links"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="text-field"
                        type="search"
                        InputProps={{
                            startAdornment: (
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{
                                        marginRight: '8px',
                                        color: '#757575',
                                    }}
                                />
                            ),
                        }}
                    />
                </div>
                <div className="filter-btn-container">
                    <Button
                        variant="outlined"
                        className="filter-btn"
                        onClick={() => setFilterModalOpen(true)}
                        style={{ textTransform: 'none' }}
                    >
                        <FontAwesomeIcon icon={faSliders} />
                        <div className="btn-label">Add Filters</div>
                    </Button>
                </div>
            </div>

            <TableContainer
                className="links-table"
                style={{ borderRadius: '0 0 6px 6px' }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {/* title  */}
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'title'}
                                    direction={order}
                                    onClick={() => handleSort('title')}
                                >
                                    Title
                                </TableSortLabel>
                            </TableCell>

                            {/* url  */}
                            <TableCell className="url-column">
                                <TableSortLabel
                                    active={orderBy === 'url'}
                                    direction={order}
                                    onClick={() => handleSort('url')}
                                >
                                    URL
                                </TableSortLabel>
                            </TableCell>

                            {/* destination  */}
                            <TableCell className="destination-column">
                                <TableSortLabel
                                    active={orderBy === 'destinationUrl'}
                                    direction={order}
                                    onClick={() => handleSort('destinationUrl')}
                                >
                                    Destination URL
                                </TableSortLabel>
                            </TableCell>

                            {/* totalClicks  */}
                            <TableCell className="total-clicks-column">
                                <TableSortLabel
                                    active={orderBy === 'totalClicks'}
                                    direction={order}
                                    onClick={() => handleSort('totalClicks')}
                                >
                                    Clicks
                                </TableSortLabel>
                            </TableCell>

                            {/* created at  */}
                            <TableCell className="created-at-column">
                                <TableSortLabel
                                    active={orderBy === 'createdAt'}
                                    direction={order}
                                    onClick={() => handleSort('createdAt')}
                                >
                                    Created At
                                </TableSortLabel>
                            </TableCell>

                            {/* actions  */}
                            <TableCell className="actions-column">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow
                                    key={row.slug}
                                    className="table-row"
                                    // style={{
                                    //     background:
                                    //         index % 2 === 0
                                    //             ? 'white'
                                    //             : '#f0f0f0',
                                    // }}
                                >
                                    {/* title  */}
                                    <TableCell
                                        className="table-cell"
                                        onClick={() => handleRowClick(row)}
                                    >
                                        {row.title}
                                    </TableCell>

                                    {/* url  */}
                                    <TableCell
                                        className="table-cell url-column"
                                        onClick={() => handleRowClick(row)}
                                    >
                                        {row.url}
                                    </TableCell>

                                    {/* destination  */}
                                    <TableCell
                                        className="table-cell destination-cell destination-column"
                                        onClick={() => handleRowClick(row)}
                                    >
                                        {row.destinationUrl}
                                    </TableCell>

                                    {/* totalClicks  */}
                                    <TableCell
                                        className="table-cell total-clicks-cell total-clicks-column"
                                        onClick={() => handleRowClick(row)}
                                    >
                                        {row.totalClicks}
                                    </TableCell>

                                    {/* created at  */}
                                    <TableCell
                                        className="table-cell created-at-column"
                                        onClick={() => handleRowClick(row)}
                                    >
                                        {row.createdAt}
                                    </TableCell>

                                    {/* actions */}
                                    <TableCell className="link-actions-container actions-column">
                                        <LinkActions
                                            link={row}
                                            onUpdate={onUpdate}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>

                    {/* footer  */}
                    <TableFooter className="table-footer">
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100]}
                                colSpan={6}
                                count={sortedData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage="Links per page"
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            {/* filter modal  */}
            <FilterModal
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                onApply={onApply}
                initialLinkType={linkType}
                initialDateRange={dateRange}
            />
        </div>
    );
};

export default LinksTable;
