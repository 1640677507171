import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faXmark,
    faHome,
    faGear,
    faLink,
    faChartSimple,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import './SideBarMobile.scss';

const SideBarMobile = () => {
    const { isAdmin } = useAuth();
    const [isExpanded, setExpanded] = useState(false);
    const location = useLocation();

    const toggleNavigation = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div className={`mobile-navigation ${isExpanded ? 'expanded' : ''}`}>
            <div className="toggle-button" onClick={toggleNavigation}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className="sidebar-links-container">
                <div className="close-icon" onClick={toggleNavigation}>
                    <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="btn-container">
                    <Link to="/link/create" onClick={toggleNavigation}>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: 'none',
                            }}
                            className="btn"
                        >
                            Create New
                        </Button>
                    </Link>
                </div>
                <ul className="menu-items">
                    <li className="divider-item"></li>
                    <li>
                        <Link
                            to="/home"
                            onClick={toggleNavigation}
                            className={
                                location.pathname === '/home' ? 'active' : ''
                            }
                        >
                            <FontAwesomeIcon icon={faHome} />
                            <span className="item-text">Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/links"
                            onClick={toggleNavigation}
                            className={
                                location.pathname === '/links' ? 'active' : ''
                            }
                        >
                            <FontAwesomeIcon icon={faLink} />
                            <span className="item-text">Links</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/analytics"
                            onClick={toggleNavigation}
                            className={
                                location.pathname === '/analytics'
                                    ? 'active'
                                    : ''
                            }
                        >
                            <FontAwesomeIcon icon={faChartSimple} />
                            <span className="item-text">Analytics</span>
                        </Link>
                    </li>
                    {/* admin routes  */}
                    {isAdmin() && (
                        <>
                            <li>
                                <Link
                                    to="/users"
                                    onClick={toggleNavigation}
                                    className={
                                        location.pathname === '/users'
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="item-text">Users</span>
                                </Link>
                            </li>
                        </>
                    )}
                    <li className="divider-item"></li>
                    <li>
                        <Link
                            to="/settings"
                            onClick={toggleNavigation}
                            className={
                                location.pathname === '/settings'
                                    ? 'active'
                                    : ''
                            }
                        >
                            <FontAwesomeIcon icon={faGear} />
                            <span className="item-text">Settings</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SideBarMobile;
