import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faBars,
    faXmark,
    faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';

import './Header.scss';

const Header = () => {
    const { user, signOut } = useAuth();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setExpanded] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const signOutAndCloseDropdown = () => {
        signOut();
        setIsOpen(false);
        navigate('/signin');
    };

    const closeDropdown = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    const toggleNav = () => {
        setExpanded(!isExpanded);
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, [user]);

    return (
        <header className="header-component">
            <nav className="nav">
                {user ? (
                    <ul className="nav-list">
                        <li className="nav-item"></li>
                        {user.plan_id === 1 && (
                            <li className="upgrade-item">
                                <div>
                                    <Link to="/upgrade">
                                        <Button
                                            variant="contained"
                                            style={{
                                                textTransform: 'none',
                                                // minWidth: '0px','
                                            }}
                                            className="upgrade-btn"
                                        >
                                            Upgrade
                                        </Button>
                                    </Link>
                                </div>
                            </li>
                        )}
                        <li className="">
                            <div className="dropdown-container">
                                <div className="dropdown" ref={dropdownRef}>
                                    <div
                                        className="dropdown-toggle"
                                        onClick={toggleDropdown}
                                    >
                                        <div className="user-initial-circle">
                                            {user.first_name.charAt(0)}
                                        </div>
                                        <div className="user-name">
                                            {user.first_name} {user.last_name}
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                className="link-icon"
                                            />
                                        </div>
                                    </div>
                                    {isOpen && (
                                        <div className="dropdown-menu">
                                            <div className="user-detail">
                                                <div className="user-initial-circle">
                                                    {user.first_name.charAt(0)}
                                                    {user.last_name.charAt(0)}
                                                </div>
                                                <div>
                                                    <div className="user-name">
                                                        {user.first_name}{' '}
                                                        {user.last_name}
                                                    </div>
                                                    <div className="user-name email">
                                                        {user.email}
                                                    </div>
                                                </div>
                                            </div>
                                            {user.plan_id === 1 && (
                                                <div
                                                    className="body-link upgrade-link"
                                                    onClick={() =>
                                                        setIsOpen(false)
                                                    }
                                                >
                                                    <Link to="/upgrade">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faArrowCircleUp
                                                            }
                                                            className=""
                                                        />{' '}
                                                        Upgrade account
                                                    </Link>
                                                </div>
                                            )}
                                            <div
                                                className="body-link"
                                                onClick={() => setIsOpen(false)}
                                            >
                                                <Link to="/support">
                                                    Support
                                                </Link>
                                            </div>
                                            <div
                                                className="body-link"
                                                onClick={() => setIsOpen(false)}
                                            >
                                                <Link to="/terms-of-service?showInApp=true">
                                                    LemurLinks Terms
                                                </Link>
                                            </div>
                                            <div className="signout-block">
                                                <Button
                                                    onClick={
                                                        signOutAndCloseDropdown
                                                    }
                                                    style={{
                                                        textTransform: 'none',
                                                        textAlign: 'left',
                                                    }}
                                                    className="signout-btn"
                                                >
                                                    Sign Out
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    </ul>
                ) : (
                    <>
                        <ul className="nav-list">
                            <li className="nav-item main-link">
                                <div>
                                    <Link to="/">LEMURLINKS</Link>
                                </div>
                            </li>
                            <div className="nav-dekstop">
                                <li className="nav-item">
                                    <div>
                                        <Link to="/plans">Plans</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div>
                                        <Link to="/signin">Sign In</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div>
                                        <Link to="/signup">
                                            <Button
                                                variant="contained"
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                className="btn"
                                            >
                                                Sign Up
                                            </Button>
                                        </Link>
                                    </div>
                                </li>
                            </div>
                            <div className="nav-mobile" onClick={toggleNav}>
                                <FontAwesomeIcon
                                    icon={faBars}
                                    className="toggle-icon"
                                />
                            </div>
                        </ul>
                    </>
                )}
            </nav>
            <div
                className={`mobile-navigation ${isExpanded ? 'expanded' : ''}`}
            >
                <div className="mobile-nav-content">
                    <div className="mobile-header">
                        <li className="nav-item main-link">
                            <div>
                                <Link to="/" onClick={toggleNav}>
                                    LEMURLINKS
                                </Link>
                            </div>
                        </li>
                        <div className="close-icon">
                            <FontAwesomeIcon
                                icon={faXmark}
                                onClick={toggleNav}
                                className="toggle-icon-close"
                            />
                        </div>
                    </div>
                    <div>
                        <li className="nav-item">
                            <div className="fake-btn sign-in">
                                <Link
                                    to="/signin"
                                    onClick={toggleNav}
                                    className="link"
                                >
                                    Sign In
                                </Link>
                            </div>
                        </li>
                    </div>
                    <div>
                        <li className="nav-item">
                            <div className="fake-btn sign-up">
                                <Link
                                    to="/signup"
                                    onClick={toggleNav}
                                    className="link"
                                >
                                    Sign Up Free
                                </Link>
                            </div>
                        </li>
                    </div>
                    <div className="divider"></div>
                    <div>
                        <li className="nav-item">
                            <div>
                                <Link to="/" onClick={toggleNav}>
                                    Home
                                </Link>
                            </div>
                        </li>
                    </div>
                    <div>
                        <li className="nav-item">
                            <div>
                                <Link to="/plans" onClick={toggleNav}>
                                    Plans
                                </Link>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
