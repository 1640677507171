import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import {
    PieChart,
    BarChart,
    LineChart,
    Table,
    Toast,
    DateRangePicker,
    Aggregate,
} from '../index';
import { useAuth } from '../../context/AuthContext';
import './Analytics.scss';

// j-todo: add chart title click that opens modal with chart more bigger
const Analytics = ({
    pageTitle,
    linkId,
    startDate = '',
    charts = {
        aggregate: false,
        topPerforming: false,
        byDevice: false,
        byLocation: false,
        overTime: false,
        overTimeFullWidth: false,
        aggregateShowUniqueLinks: true,
    },
}) => {
    const { user, isAdmin } = useAuth();

    // get the starting date (and min date) for analytics
    // offset from today using actionLimits
    // if admin just return start of 2024 (no data before then)
    // if startDate was passed in, use that (link details created at i.e.)
    const getDefaultStartDate = () => {
        if (startDate) {
            return moment(startDate).format('YYYY-MM-DD');
        } else {
            if (isAdmin()) {
                return '2024-01-01';
            } else {
                const count = user.actionLimits.analyticsPeriod.count;
                const scale = user.actionLimits.analyticsPeriod.scale;
                return moment().subtract(count, scale).format('YYYY-MM-DD');
            }
        }
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const topPerformingCount = 10;
    const defaultStartDate = getDefaultStartDate();
    const defaultEndDate = moment().format('YYYY-MM-DD'); // today
    const defaultLocationType = 'region';
    const locationOptions = [
        {
            name: 'Region',
            id: 'region',
        },
        {
            name: 'City',
            id: 'city',
        },
        {
            name: 'Country',
            id: 'country',
        },
        // this works but leaving out for now, seems not really needed
        // {
        //     id: 'postal_code',
        //     name: 'Postal Code',
        // },
    ];

    const [aggregateDataLoading, setAggregateDataLoading] = useState(true);
    const [aggregateData, setAggregateData] = useState([]);
    const [deviceDataLoading, setDeviceDataLoading] = useState(true);
    const [deviceData, setDeviceData] = useState([]);
    const [locationDataLoading, setLocationDataLoading] = useState(true);
    const [locationData, setLocationData] = useState([]);
    const [topPerformingDataLoading, setTopPerformingDataLoading] =
        useState(true);
    const [topPerformingData, setTopPerformingData] = useState([]);
    const [overTimeDataLoading, setOverTimeDataLoading] = useState(true);
    const [overTimeData, setOverTimeData] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });
    const [locationType, setLocationType] = useState(defaultLocationType);

    // fetch aggregate data
    const fetchAggregateData = async (params) => {
        setAggregateDataLoading(true);

        const paramsToSend = {
            ...params,
        };

        if (linkId) {
            paramsToSend.linkId = linkId;
        }

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/aggregate`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: paramsToSend,
                }
            );
            // console.log('reposnse', response.data.linkClicks);
            // j-todo: make sure this exists?
            setAggregateData(response.data.linkClicks);
        } catch (error) {
            console.error('Fetch aggregate data error:', error);
            throw error;
        } finally {
            setAggregateDataLoading(false);
        }
    };

    // fetch device data
    const fetchDeviceData = async (params) => {
        setDeviceDataLoading(true);

        const paramsToSend = {
            ...params,
        };

        if (linkId) {
            paramsToSend.linkId = linkId;
        }

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/by-device`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: paramsToSend,
                }
            );
            // j-todo: make sure this exists?
            setDeviceData(response.data.linkClicks.linkClicksByDevice);
        } catch (error) {
            console.error('Fetch device data error:', error);
            throw error;
        } finally {
            setDeviceDataLoading(false);
        }
    };

    // fetch location data
    const fetchLocationData = async (params) => {
        setLocationDataLoading(true);

        const paramsToSend = {
            ...params,
        };

        if (linkId) {
            paramsToSend.linkId = linkId;
        }

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/by-location`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: paramsToSend,
                }
            );
            // j-todo: make sure this exists?
            const tabledata = response.data.linkClicks.linkClicksByLocation.map(
                (click) => {
                    return {
                        ...click,
                        locationValue:
                            click.country ||
                            click.city ||
                            click.region ||
                            click.postal_code,
                    };
                }
            );
            setLocationData(tabledata);
        } catch (error) {
            console.error('Fetch location data error:', error);
            throw error;
        } finally {
            setLocationDataLoading(false);
        }
    };

    // fetch top performing
    const fetchTopPeformingLinks = async (params) => {
        setTopPerformingDataLoading(true);

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/top-performing`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: {
                        ...params,
                    },
                }
            );
            // j-todo: make sure this exists?
            const chartData = response.data.linkClicks.map((link) => {
                return {
                    link: `lemr.link/${link.slug}`,
                    count: link.count,
                    title: link.title,
                    xLabel: link.title || `lemr.link/${link.slug}`,
                };
            });
            setTopPerformingData(chartData);
        } catch (error) {
            console.error('Fetch top performing error:', error);
            throw error;
        } finally {
            setTopPerformingDataLoading(false);
        }
    };

    // fetch over time data
    const fetchOverTimeData = async (params) => {
        setOverTimeDataLoading(true);

        const paramsToSend = {
            ...params,
        };

        if (linkId) {
            paramsToSend.linkId = linkId;
        }

        try {
            const response = await axios.get(
                `${apiBaseUrl}/link-clicks/over-time`,
                {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                    params: paramsToSend,
                }
            );

            // j-todo: make sure this exists?
            const chartData = response.data.linkClicks.map((day) => {
                return {
                    date: moment(day.clickDate).format('MM/DD/YY'),
                    clicks: day.totalClicks,
                };
            });

            setOverTimeData(chartData);
        } catch (error) {
            console.error('Fetch over time data error:', error);
            throw error;
        } finally {
            setOverTimeDataLoading(false);
        }
    };

    // location type changed, fetch new locationData
    const handleLocationTypeChange = async (event) => {
        const newValue = event.target.value;
        setLocationType(newValue);

        fetchLocationData({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            locationType: newValue,
        });
    };

    // date range change, fetch all
    const onDateChange = (newDateRange) => {
        setDateRange(newDateRange);

        fetchAll({
            startDate: newDateRange.startDate,
            endDate: newDateRange.endDate,
        });
    };

    // fetch all data for charts
    // use the value stored in locationType
    // use the default value of topPerformingCount for count
    const fetchAll = async (params) => {
        const { startDate, endDate } = params;

        try {
            // Array to store all promises for API calls
            const apiPromises = [
                fetchAggregateData({ startDate, endDate }),
                fetchDeviceData({ startDate, endDate }),
                fetchLocationData({ startDate, endDate, locationType }),
                fetchTopPeformingLinks({
                    startDate,
                    endDate,
                    count: topPerformingCount,
                }),
                fetchOverTimeData({ startDate, endDate }),
            ];

            // Wait for all promises to resolve
            await Promise.all(apiPromises);
        } catch (error) {
            Toast.error('Problem fetching analytics data');
        }
    };

    useEffect(() => {
        fetchAll({ startDate: defaultStartDate, endDate: defaultEndDate });
    }, []);

    return (
        <div className="analytics-container">
            <div className="main-content">
                {pageTitle && (
                    <div className="analytics-header">{pageTitle}</div>
                )}
                <div className="card date-card">
                    <div className="date-range-component">
                        <DateRangePicker
                            onChange={onDateChange}
                            minDate={defaultStartDate}
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                        />
                    </div>
                </div>
                {charts.aggregate && (
                    <div className="card">
                        {/* <h2 className="header-date">
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className="data-icon"
                            />
                            {moment(dateRange.startDate).format('MMM D, YYYY')}{' '}
                            - {moment(dateRange.endDate).format('MMM D, YYYY')}
                        </h2> */}
                        <Aggregate
                            isLoading={aggregateDataLoading}
                            data={aggregateData}
                            aggregateShowUniqueLinks={
                                charts.aggregateShowUniqueLinks
                            }
                            dateRange={dateRange}
                        />
                    </div>
                )}
                {charts.overTimeFullWidth && (
                    <div className="card">
                        <h2 className="header">Clicks Over Time</h2>
                        <LineChart
                            isLoading={overTimeDataLoading}
                            data={overTimeData}
                        />
                    </div>
                )}
                <div className="charts-container">
                    {charts.topPerforming && (
                        <div className="card">
                            <h2 className="header">Top Performing Links</h2>
                            <BarChart
                                isLoading={topPerformingDataLoading}
                                data={topPerformingData}
                            />
                        </div>
                    )}
                    {charts.overTime && (
                        <div className="card">
                            <h2 className="header">Clicks Over Time</h2>
                            <LineChart
                                isLoading={overTimeDataLoading}
                                data={overTimeData}
                            />
                        </div>
                    )}
                    {charts.byDevice && (
                        <div className="card">
                            <h2 className="header">Clicks by Device</h2>
                            <PieChart
                                isLoading={deviceDataLoading}
                                data={deviceData}
                            />
                        </div>
                    )}
                    {charts.byLocation && (
                        <div className="card">
                            <div className="header-container">
                                <h2 className="header location-header">
                                    Clicks by Location
                                </h2>
                                <div className="location-control-container">
                                    <FormControl
                                        variant="standard"
                                        className="form-control"
                                    >
                                        <InputLabel>Location Type</InputLabel>
                                        <Select
                                            id="locationType"
                                            label="Location Type"
                                            variant="standard"
                                            name="locationType"
                                            value={locationType}
                                            onChange={handleLocationTypeChange}
                                            className="text-field"
                                            required
                                            style={{ textAlign: 'left' }}
                                        >
                                            {locationOptions.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <Table
                                isLoading={locationDataLoading}
                                data={locationData}
                                locationType={locationType}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Analytics;
