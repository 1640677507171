import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import useStripePromise from '../../hooks/useStripePromise';
import { Checkout } from './Checkout';
import './SignUpBilling.scss';

// j-todo:
/**
 * for upgrade from free:
 * pass in address if they have it
 * then update the user record in db to new plan
 * and add strip customer id etc.
 *
 * RENAME this since it works as a general component!
 *
 */
const SignUpBilling = ({
    name = '',
    email = '',
    planId,
    onBackClick,
    plans,
    onSuccess,
    token = '',
}) => {
    const [planOptions, setPlanOptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const stripePromise = useStripePromise();

    // change plans and update url
    const changePlans = (planId) => {
        const newPlan = planOptions.find((plan) => plan.id === planId);
        setSelectedPlan(newPlan);
    };

    // on the way back to signup, reattach the token that was given to us
    // when going to payment page
    const handleSuccess = (data) => {
        onSuccess({ ...data, token, planId: selectedPlan.id });
    };

    useEffect(() => {
        setPlanOptions(plans.filter((plan) => plan.id !== 1));
        setSelectedPlan(plans.find((plan) => plan.id === planId));
    }, []);

    return (
        <div className="signup-billing-container">
            <div className="main-content">
                <div className="content-body">
                    <div>
                        <div className="back-btn-container">
                            <span
                                className="back-btn"
                                onClick={() => onBackClick(selectedPlan.id)}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    className="back-icon"
                                />{' '}
                                Back
                            </span>
                        </div>
                        <div className="form-header">Payment Details</div>
                        <div className="plan-header">Selected Plan</div>
                        <div className="plans-container">
                            {planOptions.map((plan, key) => {
                                return (
                                    <div
                                        onClick={() => changePlans(plan.id)}
                                        key={key}
                                        className={
                                            selectedPlan.id === plan.id
                                                ? 'plan selected-plan'
                                                : 'plan'
                                        }
                                    >
                                        <div className="plan-name">
                                            {plan.name}
                                        </div>
                                        <div>{plan.formattedAmount}/month</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <Checkout
                        stripePromise={stripePromise}
                        email={email}
                        name={name}
                        onSuccess={handleSuccess}
                        selectedPlan={selectedPlan}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignUpBilling;
