import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEnvelopeCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import './Verify.scss';

const Verify = () => {
    const { token } = useParams(); // If you're passing the token as a route parameter
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [error, setError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${apiBaseUrl}/verify?token=${token}`)
            .then((data) => {
                setIsVerified(true);
            })
            .catch((error) => {
                console.error('Error verifying emails:', error);
                if (error.response.status === 400) {
                    setError('This link is invalid or already verified.');
                } else {
                    setError('There was a problem verifying.');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [token]);

    return (
        <div className="verify-component">
            <div className="content">
                {isLoading && (
                    <>
                        <h1 className="header">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="icon fa-bounce"
                            />
                            Verifying email...
                        </h1>
                        <div className="spacer"></div>
                    </>
                )}
                {isVerified && (
                    <>
                        <h1 className="header">
                            <FontAwesomeIcon
                                icon={faEnvelopeCircleCheck}
                                className="icon verified-logo"
                            />
                            Email Verified!
                        </h1>
                        <div className="details">
                            Your email has been successfully verified and your
                            account has been created!
                        </div>
                        <div className="btn-container">
                            <Link to="/signin">
                                <Button
                                    variant="contained"
                                    style={{ textTransform: 'none' }}
                                >
                                    Go to sign in
                                </Button>
                            </Link>
                        </div>
                    </>
                )}
                {error && (
                    <>
                        <h1 className="header">uh-oh</h1>
                        <div className="details">{error}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Verify;
